<template>
    <form id="account-profile" @submit.prevent="saveProfile" class="flex flex-col items-center mb-30">
        <div class="mx-auto w-full max-w-[700px] border border-gray-300 rounded mb-30">
            <div class="bg-gray-100 p-20 border-b border-gray-300 rounded-tr rounded-tl font-semibold text-lg">Your Details</div>
            <div v-if="profile" class="bg-white p-20 rounded-br rounded-bl">

                <div class="flex flex-col mb-20">
                    <FormLabel>Email address</FormLabel>
                    <TextInput v-model="profile.email" type="email" name="email" />
                    <ValidationError v-if="errors.email" @click="errors.email=false">{{ errors.email[0] }}</ValidationError>
                </div>

                <div class="flex flex-col mb-20">
                    <FormLabel>Full name</FormLabel>
                    <TextInput v-model="profile.profile.full_name" type="text" name="name" />
                    <ValidationError v-if="errors.name" @click="errors.name=false">{{ errors.name[0] }}</ValidationError>
                </div>

                <div class="flex flex-col mb-20">
                    <FormLabel>Phone number</FormLabel>
                    <TextInput v-model="profile.profile.phone" type="text" name="phone" />
                    <ValidationError v-if="errors.phone" @click="errors.phone=false">{{ errors.phone[0] }}</ValidationError>
                </div>

                <div class="flex flex-col mb-20">
                    <FormLabel>Company name</FormLabel>
                    <TextInput v-model="profile.profile.company" type="text" name="company" />
                    <ValidationError v-if="errors.company" @click="errors.company=false">{{ errors.company[0] }}</ValidationError>
                </div>

                <div class="flex flex-col mb-20">
                    <FormLabel>CITB Levy number (If known)</FormLabel>
                    <TextInput v-model="profile.profile.citb_levy_number" type="text" name="citb" />
                    <ValidationError v-if="errors.citb" @click="errors.citb=false">{{ errors.citb[0] }}</ValidationError>
                </div>
            </div>
        </div>
        <SubmitButton type="submit">Continue</SubmitButton>
    </form>
</template>

<script>
    export default {
        data() {
            return {
                updating: false,
                profile: null,
                order: {},
                errors: {},
                success: '',
                profileUpdateFailed: false
            }
        },
        props: ['checkoutUser', 'checkoutOrder'],
        mounted() {
            this.profile = this.checkoutUser
        },
        methods: {
            saveProfile() {
                this.updating = true
                this.errors = {}
                this.success = false
                this.profileUpdateFailed = false

                let formData = new FormData(document.getElementById('account-profile'))
                formData.append('orderId', this.checkoutOrder.id)
                formData.append('redirectUrl', '/book/attendees/'+this.checkoutOrder.id)

                axios.post(window.location.href, formData, {
                    headers: { 'X-OCTOBER-REQUEST-HANDLER' : 'onSaveProfile' }
                }).then(response => {
                    window.location = response.data.redirectUrl
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.profileUpdateFailed = "Something went wrong. Try again."
                        throw error;
                    }
                }).then(() => {
                    this.updating = false
                });
            }
        }
    }
</script>