<template>
    <div class="w-full sm:w-4/5 md:w-3/4 lg:w-3/5 xl:w-2/5">
        <p v-if="loginFailed" class="text-red-500 mb-30">{{ loginFailed }}</p>

        <account-heading>Login</account-heading>

        <form @submit.prevent="login" class="rounded-lg bg-gray-50 border border-gray-200 p-30 mb-30">

            <div class="flex flex-col mb-20">
                <FormLabel for="login-input">Email Address</FormLabel>
                <TextInput v-model="email" id="login-input" type="text" name="login" placeholder="Enter your email address" />
                <ValidationError v-if="errors.login" class="text-red-500 my-5">{{ errors.login[0] }}</ValidationError>
            </div>

            <div class="flex flex-col mb-20">
                <FormLabel for="password-input">Password</FormLabel>
                <PasswordInput v-model="password" id="password-input" type="password" name="password" placeholder="Enter your password" />
                <ValidationError v-if="errors.password" class="text-red-500 my-5">{{ errors.password[0] }}</ValidationError>
            </div>

            <button type="submit" class="w-auto bg-orange text-white font-semibold py-15 px-20 rounded">Login</button>
        </form>

        <p class="mb-20 font-medium">Forgot your password? <a href="/reset-password" class="text-orange cursor-pointer hover:underline font-semibold">Click here to reset your password.</a></p>

        <a href="/register" class="block bg-gray-50 p-30 border border-gray-200 hover:bg-gray-100 rounded-lg">
            <span class="font-bold text-2xl">Don't have an account?</span>
            <span class="block">Click here to register with Essential Site Skills.</span>
        </a>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                errors: {},
                email: '',
                password: '',
                loginFailed: false
            }
        },

        props: {
            redirectUrl: {
                type: String,
                default: ""
            }
        },

        methods: {
            login() {
                this.loading = true
                this.errors = {}
                this.loginFailed = false

                axios.post(window.location.href, {
                    login: this.email,
                    password: this.password,
                    redirectUrl: this.redirectUrl
                }, {
                    headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onLogin'}
                }).then(response => {
                    window.location.replace(response.data.redirectUrl);
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.loginFailed = error.response.data
                        throw error;
                    }
                }).then(() => {
                    this.loading = false
                })
            }
        }
    }
</script>