<template>
    <div v-if="navItems" id="nav-wrap"
        :class="navWrapClassObject"
        class="h-full w-full fixed z-20 left-0 overflow-x-scroll lg:relative lg:flex lg:opacity-100 lg:visible lg:overflow-visible bg-gray-800 lg:text-xs xl:text-sm">
        <nav id="nav"
            class="lg:container mx-auto w-full bg-white lg:bg-gray-800 mt-0 z-10 relative lg:h-full lg:flex lg:items-center">

            <nav-list :nav-items="items" :user-logged-in="userLoggedIn" top-level></nav-list>

            <div class="px-20 pt-20 border-t-2 lg:hidden">
                <div v-if="userLoggedIn">
                    <a href="/account" class="py-10 block text-lg font-bold text-primary hover:text-secondary transition duration-300">
                        <font-awesome-icon :icon="['fal', 'user']" class="mr-10" fixed-width></font-awesome-icon>My Account
                    </a>
                    <logout-button :buttonClass="'py-10 block text-lg font-bold text-primary hover:text-secondary'" :iconClass="'mr-10'"></logout-button>
                </div>
                <div v-else>
                    <a href="/register" class="py-10 block text-lg font-bold text-primary hover:text-secondary transition duration-300">
                        <font-awesome-icon :icon="['fal', 'user-plus']" class="mr-10" fixed-width></font-awesome-icon>Register
                    </a>
                    <a href="/login" class="py-10 block text-lg font-bold text-primary hover:text-secondary transition duration-300">
                        <font-awesome-icon :icon="['fal', 'sign-in']" class="mr-10" fixed-width></font-awesome-icon>Log in
                    </a>
                </div>
            </div>

        </nav>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                viewportHeight: 100,
                headerHeight: 105,
                active: false,
                items: {}
            }
        },

        props: {
            'nav-items': {
                type: String,
                default: ''
            },
            'user-logged-in': {
                type: Boolean,
                default: false
            }
        },

        computed: {
            /**
             *
             * Determines what css classes will be applied to the
             * nav wrap element, depending on active status
             *
             */
            navWrapClassObject() {
                return {
                    'is-active opacity-100 visible bg-white lg:bg-gray-800' : this.active,
                    'opacity-0 invisible' : !this.active
                }
            },

            /**
             * CSS class to be toggled on the html <body> element
             * depending on whether the nav is active or not
             */
            bodyClass() {
                return this.active ? 'fixed w-full' : '';
            }
        },

        mounted() {
            /**
             * Parse nav items prop data into JSON
             */
            this.items = JSON.parse(this.navItems)

            this.setAnimationVariables()

            /**
             * Things to down when the navigation is toggled
             */
            this.emitter.on('toggled-nav', (menuState) => {
                this.setAnimationVariables()
                this.active = menuState
                document.getElementsByTagName('body')[0].className = this.bodyClass
            });
        },

        methods: {
            setAnimationVariables() {
                let hh = document.getElementById('header').offsetHeight
                this.headerHeight = hh.toString()+'px'

                let vh = window.innerHeight - hh
                this.viewportHeight = vh.toString()+'px'
            }
        }
    }
</script>

<style lang="scss">
@media (max-width: 1023px) {
    #nav-wrap {
        top: 100%;
        transition: .5s;

        &.is-active {
            top: v-bind(headerHeight);
            height: v-bind(viewportHeight);
            transition: .5s;
        }
    }
}

@media (min-width: 1024px) {
    #nav {
        .dropdown {
            width: 300px;
            top: 100%;

            .dropdown {
                top: 0;
                left: 100%;
            }
        }

        .parent:hover > .dropdown {
            display: block;
        }
    }
}
</style>