<template>
    <select v-model="value" :id="id"
        class="p-10 text-gray-700 bg-white rounded font-semibold border border-black">
        <option v-for="option, index in options" :key="index" :value="option.value">{{ option.label }}</option>
    </select>
</template>

<script setup>

import { computed } from 'vue'

const emit = defineEmits(['update:model-value'])

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit("update:model-value", value)
    }
})

const props = defineProps({
    id: {
        type: [Boolean, String],
        default: false
    },
    label: {
        type: String,
        default: ''
    },
    modelValue: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        default: ''
    },
    options: {
        type: Array,
        default: [
            { label: "Health and Safety", value: "health-and-safety" },
            { label: "First aid", value: "first-aid" },
            { label: "Working at heights", value: "working-at-heights" }
        ]
    }
})

</script>