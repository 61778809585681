<template>
    <div v-if="state.shown">
        <div class="container mx-auto group flex flex-col gap-10 md:gap-30 md:flex-row md:items-center justify-between text-sm p-10 hover:bg-white rounded overflow-hidden transition-all duration-300">
            <p class="flex-1 font-semibold md:truncate group-hover:text-blue transition-all duration-300">
                {{ course_title }} starting on {{ course_start_date }}
            </p>
            <div class="py-10">
                <a rel="nofollow" :href="resume_url" class="text-center flex-nowrap rounded bg-blue hover:bg-blue-400 text-white font-semibold px-10 py-10 transition-all duration-300">Continue Booking</a>
                <button @click="remove" type="button" title="Dismiss" class="flex-shrink p-10 mx-5 hover:bg-gray-50 rounded-md"><font-awesome-icon :icon="['fal', 'times']" fixed-width class="text-gray"></font-awesome-icon></button>
            </div>
        </div>
    </div>
</template>

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0
    }
</style>

<script setup>
    import { reactive } from 'vue'

    const props = defineProps({
        course_title: String,
        course_start_date: String,
        resume_url: String,
        order_id: Number
    })

    const state = reactive ({
        shown: true
    })

    function remove()
    {
        state.shown = false;

        axios({
            method: 'post',
            url: '/book/dismiss_banner/',
            data: {
                id: props.order_id,
            },
        })
    }
</script>
