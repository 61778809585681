<template>
    <div>
        <template v-if="!resetCode && !requestSent">
            <AccountHeading>Reset Password</AccountHeading>
            <p class="mb-30">Enter your email address below and we'll send you a link to reset your password.</p>
            <form @submit.prevent="resetPasswordRequest" class="p-30 bg-gray-50 border border-gray-200 rounded-lg">
                <p v-if="requestError" class="text-red-500 my-5 mb-30">{{ requestError }}</p>
                <div class="flex flex-col mb-20">
                    <FormLabel for="email-input">Email address</FormLabel>
                    <TextInput v-model="email" id="email-input" name="email" placeholder="Please enter your email address" />
                    <ValidationError v-if="errors.email" class="text-red-500 my-5">{{ errors.email[0] }}</ValidationError>
                </div>
                <button type="submit" class="bg-orange text-white font-semibold p-10 rounded">Request Password Reset</button>
            </form>
        </template>
        <template v-else>
            <AccountHeading>Create new password</AccountHeading>
            <template v-if="requestSent">
                <p class="mb-5">We have sent you an email with a code. <a @click.prevent="requestSent = false" class="text-orange underline cursor-pointer">Send another</a>.</p>
                <p class="mb-30">Enter the code below and choose a new password.</p>
            </template>
            <form @submit.prevent="resetPassword" class="p-30 bg-gray-50 border border-gray-200 rounded-lg">
                <p v-if="resetError" class="text-red-500">{{ resetError }}</p>
                <div class="flex flex-col mb-20">
                    <FormLabel for="code-input">Code</FormLabel>
                    <TextInput v-model="code" id="code-input" name="code" :disabled="codeInputDisabled" />
                    <ValidationError v-if="errors.code" class="text-red-500 my-5">{{ errors.code[0] }}</ValidationError>
                </div>
                <div class="flex flex-col mb-20">
                    <FormLabel for="password-input">New password</FormLabel>
                    <PasswordInput v-model="password" id="password-input" name="password" />
                    <ValidationError v-if="errors.password" class="text-red-500 my-5">{{ errors.password[0] }}</ValidationError>
                </div>
                <button @click.prevent="resetPassword" class="bg-orange text-white font-semibold p-10 rounded">Update Password</button>
            </form>
        </template>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                email: '',
                code: '',
                password: '',
                sending: false,
                requestSent: false,
                errors: {},
                requestError: false,
                resetError: false,
                codeInputDisabled: false
            }
        },

        mounted() {
            this.code = this.resetCode;
            if (this.resetCode) {
                this.codeInputDisabled = true
            }
        },

        props: {
            resetCode: {
                type: [String],
                default: ''
            },

            redirectUrl: {
                type: [String,Boolean],
                default: false
            }
        },

        methods: {

            /**
             *
             * Request password update and send code to user email
             *
             */
            resetPasswordRequest() {
                this.sending = true
                this.errors = {}
                this.requestError = false

                axios.post(window.location.href, {
                    email: this.email,
                }, {
                    headers: { 'X-OCTOBER-REQUEST-HANDLER' : 'onResetPasswordRequest' }
                }).then(response => {
                    this.requestSent = true
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.requestError = error.response.data
                        throw error;
                    }
                }).then(() => {
                    this.sending = false
                })
            },

            /**
             *
             * Verify the given code and update password
             *
             */
            resetPassword() {
                this.sending = true
                this.errors = {}
                this.resetError = false

                axios.post(window.location.href, {
                    code: this.code,
                    password: this.password,
                    redirectUrl: this.redirectUrl
                }, {
                    headers: { 'X-OCTOBER-REQUEST-HANDLER' : 'onResetPassword' }
                }).then(response => {
                    if (response.data.redirectUrl) {
                        window.location.replace(response.data.redirectUrl);
                    }

                    this.$emit('password-reset-successfully', response.data.success);
                    this.requestSent = false
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.resetError = error.response.data
                        throw error;
                    }
                }).then(() => {
                    this.sending = false
                })
            },
        }
    }
</script>