<template>
    <button @click="openModal" :class="buttonClasses">
        <slot>Make an enquiry</slot>
    </button>
    <Teleport to="body">
        <Modal :open="open" @close="closeModal" container-classes="w-full max-w-[600px] max-h-5/6 p-30 p-30 bg-white rounded-md shadow-lg tran2sition-all duration-300">
            <div>
                <p class="font-semibold text-2xl mb-10 text-center">{{ props.course.title }}</p>

                <p v-if="!state.success" class="mb-20 py-15 px-10 sm:px-50 md:px-20 lg:px-50 bg-orange-50 rounded text-center ">
                    To make an enquiry call our team on <a :href="'tel:'+phone" class="text-blue hover:underline font-semibold">{{ phone }}</a> or use the form below
                </p>

                <div v-show="!state.success">
                    <form @submit.prevent="submitForm" method="POST" ref="bookingForm">

                        <input type="hidden" name="course" :value="props.course.id" />

                        <div class="flex flex-col mb-15">
                            <FormLabel for="first_name">First name <span class="req">*</span></FormLabel>
                            <TextInput name="first_name" type="text" id="first_name" autocomplete="given-name"/>
                            <ValidationError v-if="state.errors.first_name">{{ state.errors.first_name[0] }}</ValidationError>
                        </div>

                        <div class="flex flex-col mb-15">
                            <FormLabel for="last_name">Your Last Name <span class="req">*</span></FormLabel>
                            <TextInput name="last_name" type="text" id="last_name" autocomplete="family-name" />
                            <ValidationError v-if="state.errors.last_name">{{ state.errors.last_name[0] }}</ValidationError>
                        </div>

                        <div class="flex flex-col mb-15">
                            <FormLabel for="email">Your email address<span class="req">*</span></FormLabel>
                            <TextInput name="email" type="text" id="email" autocomplete="email" />
                            <ValidationError v-if="state.errors.email">{{ state.errors.email[0] }}</ValidationError>
                        </div>

                        <div class="flex flex-col mb-15">
                            <FormLabel for="phone">Your phone number<span class="req">*</span></FormLabel>
                            <TextInput name="phone" type="text" id="phone" autocomplete="tel" />
                            <ValidationError v-if="state.errors.phone">{{ state.errors.phone[0] }}</ValidationError>
                        </div>

                        <div class="flex flex-col mb-15">
                            <FormLabel for="location">Your location<span class="req">*</span></FormLabel>
                            <TextInput name="location" type="text" id="location" />
                            <ValidationError v-if="state.errors.location">{{ state.errors.location[0] }}</ValidationError>
                        </div>

                        <div class="flex flex-col mb-15">
                            <FormLabel for="additional_info">Any additional information/requirements</FormLabel>
                            <TextareaInput name="additional_info" id="additional_info" rows="2"></TextareaInput>
                            <ValidationError v-if="state.errors.additional_info">{{ state.errors.additional_info[0] }}</ValidationError>
                        </div>

                        <div class="mb-20">
                            <Checkbox v-model="state.accepts_marketing" id="accepts_marketing_input" name="accepts_marketing">Receive marketing communications</Checkbox>
                        </div>

                        <p v-show="state.generalError" class="bg-red-100 text-red-500 font-semibold p-10 rounded mb-10">Unable to submit your enquiry. Please try again.</p>

                        <SubmitButton type="submit" id="enquiry_submit" class="w-full">
                            Submit message <font-awesome-icon :icon="['fal', buttonIcon]" v-bind:spin="state.loading" />
                        </SubmitButton>
                    </form>
                </div>

                <div v-show="state.success" class="text-center bg-orange-50 p-30 rounded-lg">
                    <h4 class="font-semibold text-orange text-2xl mb-10">Thank you for your enquiry</h4>
                    <p class="font-semibold mb-20">A member of our team will be in touch shortly to discuss details and arrange your booking.</p>
                    <button @click.prevent="closeModal" class="bg-black text-white font-semibold rounded p-10 text-sm hover:bg-gray-800 transition-all duration-300">
                        <font-awesome-icon :icon="['fal', 'times']" class="mr-10"></font-awesome-icon>Close
                    </button>
                </div>

            </div>
        </Modal>
    </Teleport>
</template>
<script setup>

import { ref, reactive, computed } from 'vue'

const bookingForm = ref(null)

const open = ref(false)

const state = reactive({
    generalError: false,
    errors: false,
    loading: false,
    success: false,
    accepts_marketing: false
})

const props = defineProps({
    phone: {
        default: "0115 8970 529"
    },
    course: {
        default: null
    },
    courseType: {
        default: 'course'
    },
    buttonClasses: {
        default: "w-full flex items-center sm:flex-1 justify-center text-white p-15 text-center rounded font-semibold bg-black"
    }
})

const buttonIcon = computed(() => {
    if (state.loading) {
        return 'spinner-third'
    }

    if (state.success) {
        return 'check'
    }

    return 'arrow-right'
})

function submitForm() {
    state.loading = true;
    state.generalError = false;
    state.errors = false;

    let formData = new FormData(bookingForm.value);

    formData.append('course_type', props.courseType);

    axios({
        method: 'post',
        url: window.location.href,
        data: formData,
        headers: {
            'X-OCTOBER-REQUEST-HANDLER': 'onSubmitEnquiry',
            'X-Requested-With': 'XMLHttpRequest'
        }
    }).then(response => {
        state.success = true;
        bookingForm.value.reset();
    }).catch(e => {
        if (e.response?.status == 406) {
            state.errors = e.response.data.X_OCTOBER_ERROR_FIELDS;
        } else {
            state.generalError = e.message;
            throw e;
        }
    }).then(() => {
        state.loading = false;
    });
}

function closeModal() {
    open.value = false
    document.getElementsByTagName('body')[0].classList.remove('fixed')
    state.success = false
    state.errors = false
    state.generalError = false
}

function openModal() {
    open.value = true
    document.getElementsByTagName('body')[0].classList.add('fixed')
}
</script>