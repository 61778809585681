<template>
    <div>
        <checkout-user-details-profile v-if="checkoutUser" :checkout-user="checkoutUser" :checkout-order="checkoutOrder" />
        <template v-else>
            <div class="flex flex-col items-center mb-30">
                <div class="mx-auto w-full max-w-[700px] border border-gray-300 rounded mb-30">
                    <div class="bg-gray-100 p-20 border-b border-gray-300 rounded-tr rounded-tl">Account</div>
                    <div class="bg-white p-20 rounded-br rounded-bl">
                        <template v-if="showOptions">
                            <label for="loginRegister" class="text-sm font-semibold mb-10 block">How would you like to continue?</label>
                            <div id="login-register-options" class="flex flex-col md:flex-row md:mb-20">
                                <label for="loginRegister_Register" class="cursor-pointer flex items-center mb-10 md:mb-0 md:mr-60">
                                    <input id="loginRegister_Register" v-model="loginRegister" type="radio" name="loginRegister" value="register" class="w-20 h-20 mr-10" >
                                    <span>Create account</span>
                                </label>
                                <label for="loginRegister_Login" class="cursor-pointer flex items-center mb-10 md:mb-0">
                                    <input id="loginRegister_Login" v-model="loginRegister" type="radio" name="loginRegister" value="login" class="w-20 h-20 mr-10" >
                                    <span>Login</span>
                                </label>
                            </div>
                        </template>
                        <checkout-user-register v-show="loginRegister==='register'" :checkout-order="checkoutOrder" />
                        <checkout-user-login v-show="loginRegister==='login'" :checkout-order="checkoutOrder" :password-reset-code="passwordResetCode" />
                    </div>
                </div>
                <font-awesome-icon class="text-pink" v-if="submitting" :icon="['fal', 'spinner-third']" spin />
                <button
                    v-else
                    @click.prevent="onLoginRegister"
                    class="text-white bg-orange px-40 py-10 rounded">
                    Continue
                </button>
            </div>
        </template>
    </div>
</template>
<script>

    export default {
        data() {
            return {
                showOptions: true,
                submitting: false,
                loginRegister: 'login'
            }
        },
        mounted() {
            this.emitter.on('finished-register-login', () => {
                this.submitting = false
            });
            this.emitter.on('toggled-password-reset', (newValue) => {
                this.showOptions = !newValue
            });
        },
        props: [
            'checkoutOrder',
            'checkoutUser',
            'passwordResetCode'
        ],
        methods: {
            onLoginRegister() {
                this.submitting = true
                this.emitter.emit(this.loginRegister)
            }
        }
    }
</script>