<template>

    <h1 class="font-bold mb-20 text-lg lg:text-4xl">{{ course.title }}</h1>

    <div v-if="props.courseInstance" class="relative flex flex-col sm:flex-row sm:items-center sm:gap-10 bg-orange-100 p-20 mb-10 rounded-lg lg:mb-30">
        <div class="text-xs font-semibold uppercase mb-10 sm:mb-0 sm:bg-black sm:text-white sm:flex sm:flex-col sm:items-center sm:py-[8px] sm:px-15 sm:rounded">
            <span>{{ formatDate(props.courseInstance.start_date, 'D') }}</span>
            <span>{{ formatDate(props.courseInstance.start_date, 'MMM') }}</span>
        </div>

        <div class="flex flex-col sm:flex-1 sm:truncate">
            <p v-if="!props.courseInstance.is_national && props.courseInstance.distance" class="text-xs">{{ props.courseInstance.distance }}</p>
            <div class="text-2xl font-bold">{{ props.courseInstance.is_national ? "Online Training" : props.courseInstance.venue_name }}</div>
            <div class="font-normal mb-10 sm:mb-0">{{ props.courseInstance.delivery }}</div>
            <div v-if="props.courseInstance.dates_string || props.courseInstance.times_string" class="flex flex-col lg:flex-row lg:gap-50">
                <p v-if="props.courseInstance.dates_string" class="text-sm">
                    <font-awesome-icon :icon="['fas', 'calendar']" fixed-width class="mr-10 text-orange"></font-awesome-icon>{{ props.courseInstance.dates_string }}
                </p>
                <p v-if="props.courseInstance.times_string" class="text-sm">
                    <font-awesome-icon :icon="['fas', 'clock']" fixed-width class="mr-10 text-orange"></font-awesome-icon>{{ props.courseInstance.times_string }}
                </p>
            </div>
        </div>

        <div class="flex items-center justify-between">
            <div class="text-2xl font-semibold sm:w-[140px] sm:text-right sm:mr-10 lg:mr-40">
                <div v-if="props.courseInstance.is_discounted" class="font-light text-xs uppercase leading-none">was &pound;{{ props.courseInstance.base_price_per_attendee }}</div>
                <div class="text-3xl leading-none">&pound;{{ props.courseInstance.price_per_attendee }}</div>
            </div>
            <div class="sm:relative">
                <a rel="nofollow" :href="'/book/course/'+props.courseInstance.id" class="flex leading-none rounded bg-blue-500 hover:bg-black text-white font-semibold text-sm text-center px-20 py-[12px]">
                    Continue Booking
                </a>
                <div class="absolute w-[120px] top-20 right-20 sm:mt-5 sm:right-auto sm:ml-[50%] sm:left-[-60px] sm:top-auto text-right sm:text-center text-black font-semibold text-xs leading-none">{{ props.courseInstance.open_spaces }} spaces left</div>
            </div>
        </div>
    </div>

    <div class="grid gap-10 md:gap-20 xl:gap-50 lg:grid-cols-[1fr_375px] grid-rows-[auto_auto_auto] lg:grid-rows-[auto_1fr]">
        <div class="lg:col-span-1 md:grid md:grid-cols-3 gap-10 md:gap-20 xl:gap-50">
            <Tile label="Accreditation" :value="course.accreditation" :icon="['fal', 'diploma']" icon-color="text-indigo-400" />
            <Tile label="Course Duration" :value="course.duration" :icon="['fal', 'calendar']" icon-color="text-teal-400" />
            <Tile label="Valid for" :value="course.certificate_expiry_string" :icon="['fal', 'timer']" icon-color="text-blue-400" />
        </div>

        <div id="course-information" class="scroll-m-[250px] lg:scroll-m-50">
            <p class="uppercase text-2xl font-bold mb-30">Course Information</p>

            <div v-if="course.course_info" class="transition-all duration-700 relative after:bottom-0 after:left-0 after:block after:absolute after:w-full after:bg-gradient-to-t from-white to-white/0 scroll-m-[300px] editor-styles mb-20 overflow-hidden" :class="{ 'max-h-200 after:h-100' : !state.expandedOverview, 'after:h-0 max-h-[99999px]' : state.expandedOverview }" v-html="course.course_info"></div>

            <button @click.prevent="toggleOverview" class="px-20 py-10 rounded-full text-sm bg-blue-50 text-blue-500 hover:bg-blue-100 transition-all duration-300 font-semibold">
                Read full course overview <font-awesome-icon :icon="['fal', expandedOverviewIcon]" class="ml-10"></font-awesome-icon>
            </button>
        </div>

        <div class="bg-white sticky lg:static py-20 md:py-10 lg:py-0 top-0 row-start-2 lg:col-start-2 lg:col-span-1 lg:row-start-1 lg:row-span-2">
            <div class="sticky top-20">
                <div class="sm:flex lg:flex-col gap-10 mb-10">
                    <template v-if="props.hasFutureDates">
                        <a href="#course-dates" class="flex sm:flex-1 justify-center text-white p-15 text-center rounded font-semibold bg-orange mb-10 sm:mb-0">Check availability and pricing</a>
                        <booking-enquiry :phone="props.phoneNumber" :course="course">Make a booking enquiry</booking-enquiry>
                    </template>
                    <div v-else class="bg-white w-full lg:flex-col gap-10 rounded-xl p-20">
                        <p class="text-lg font-semibold mb-10 text-center">To book this course</p>
                        <div class="flex flex-col sm:flex-row lg:flex-col gap-10">
                            <a href="tel:0115 8970 529" class="flex sm:flex-1 items-center justify-center text-white p-15 text-center rounded font-semibold bg-orange">Call us on 0115 8970 529</a>
                            <booking-enquiry :phone="props.phoneNumber" :course="course">Make a booking enquiry</booking-enquiry>
                        </div>
                    </div>
                </div>
                <div class="flex lg:flex-col gap-10">
                    <div class="flex-1">
                        <course-download :course="course" :url="'/course/' + course.slug + '/pdf'" >
                            Download Course Information
                        </course-download>
                    </div>
                    <div v-if="showSecondaryCta" class="bg-blue-50 lg:mt-10 lg:p-20 rounded flex flex-col flex-1">
                        <p v-if="course.secondary_cta_title" class="text-blue-500 hidden lg:block text-lg font-bold" :class="{'mb-20' : course.secondary_cta_text || (course.secondary_cta_url && course.secondary_cta_button_text) }">{{ course.secondary_cta_title }}</p>
                        <p v-if="course.secondary_cta_text" class="hidden lg:block text-xs md:text-sm lg:text-base" :class="{ 'mb-10 lg:mb-20' : course.secondary_cta_url && course.secondary_cta_button_text }">{{ course.secondary_cta_text }}</p>
                        <a v-if="course.secondary_cta_url && course.secondary_cta_button_text" :href="course.secondary_cta_url" class="lg:bg-blue lg:hover:bg-blue-700 rounded p-15 flex items-center justify-center text-center text-blue lg:text-white text-sm transition-all duration-300 h-full">{{ course.secondary_cta_button_text }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <course-dates v-if="props.hasFutureDates" :init-filters="props.filters" :show-online="props.hasFutureOnlineDates" :show-in-person="props.hasFutureInPersonDates" >
        <div class="mt-20 ">
            <div v-if="props.showEthBanner" class="mb-20 bg-[#38384c] hover:bg-[#272735] p-20 block transition-colors duration-300">
                <img src="/images/eth-logo.svg" alt="Essential Training Hub" class="h-60 mb-20">
                <p class="text-white text-xl font-bold mb-10">Can't find the right course date?</p>
                <p class="text-white mb-20">Search thousands of courses at Essential Training Hub, our trusted partner site, or <a href="/contact" class="underline hover:text-orange">contact us</a> today!</p>
                <a href="https://esstraininghub.co.uk/" target="_blank" class="text-white bg-[#e05773] hover:bg-[#cb335a] text-center p-15 block rounded text-sm transition-colors duration-300">Go to Essential Training Hub</a>
            </div>
            <section class="bg-blue-50 p-20">
                <p class="text-blue-500 text-lg font-bold mb-20">In-company training available</p>
                <p class="mb-20">We can deliver in-company, approved or accredited training courses at your premises, anywhere in the UK and customise an existing course to meet your specific objectives.</p>
                <booking-enquiry :phone="props.phoneNumber" :course="course" button-classes="w-full bg-blue-500 rounded text-white p-15 text-sm"/>
            </section>
        </div>
    </course-dates>

    <section id="assessment-and-certification" class="pt-50">
        <h3 class="font-bold text-2xl lg:text-3xl xl:text-4xl text-center mb-20 xl:mb-70">Assessment &amp; Certification</h3>

        <div class="grid grid-rows[auto_auto_auto] lg:grid-cols-[1fr_1fr] lg:grid-rows-[auto_auto] gap-30 xl:gap-50">
            <div v-if="course.assessment" class="lg:col-span-1 p-20 xl:p-70 bg-white border border-gray-100 rounded-lg shadow-xl">
                <div class="flex items-center mb-20 xl:pb-50">
                    <font-awesome-icon :icon="['fal', 'list-check']" fixed-width class="mr-15 text-green-400 xl:text-3xl"></font-awesome-icon>
                    <p class="uppercase font-bold text-lg xl:text-3xl">Assessment</p>
                </div>
                <div class="editor-styles" v-html="course.assessment" ></div>
            </div>

            <div v-if="course.certification" class="lg:col-start-2 lg:col-span-1 p-20 xl:p-70 bg-white border border-gray-100 rounded-lg shadow-xl">
                <div class="flex items-center mb-20 xl:pb-50">
                    <font-awesome-icon :icon="['fal', 'file-certificate']" fixed-width class="mr-15 text-red-400 xl:text-3xl"></font-awesome-icon>
                    <p class="uppercase font-bold text-lg xl:text-3xl">Certification</p>
                </div>
                <div class="editor-styles" v-html="course.certification"></div>
            </div>

            <div v-if="course.more_details" class="lg:row-start-2 lg:row-span-1 lg:col-span-2 p-20 xl:p-70 bg-white border border-gray-100 rounded-lg shadow-xl mb-20">
                <div class="flex items-center mb-20 xl:pb-50">
                    <font-awesome-icon :icon="['fal', 'info-square']" fixed-width class="mr-15 text-blue-400 xl:text-3xl"></font-awesome-icon>
                    <p class="uppercase font-bold text-lg xl:text-3xl">More Details</p>
                </div>
                <div class="editor-styles" v-html="course.more_details"></div>
            </div>
        </div>
    </section>

    <a v-if="props.hasFutureDates" href="#course-dates" class="flex flex-col text-center items-center justify-center p-20 bg-orange rounded text-white">
        <span class="mb-15 text-xl font-semibold" >Find a course date for</span>
        <span class="text-lg">{{ course.title }}</span>
    </a>
</template>

<script setup>

import { reactive, computed } from 'vue';
import Tile from './Tile.vue'
import moment from 'moment'

function toggleOverview() {
    state.expandedOverview = !state.expandedOverview

    document.getElementById('course-information').scrollIntoView({ behavior: 'smooth' })
}

const props = defineProps({
    course: {
        type: Object,
        default: null
    },
    filters: {
        type: Object,
        default: null
    },
    hasFutureDates: {
        type: Boolean,
        default: false
    },
    hasFutureInPersonDates: {
        type: Boolean,
        default: false
    },
    hasFutureOnlineDates: {
        type: Boolean,
        default: false
    },
    courseInstance: {
        type: Object,
        default: null
    },
    phoneNumber: {
        type: String,
        default: ""
    },
    showEthBanner: {
        type: Boolean,
        default: false
    }
})

const state = reactive({
    expandedOverview: false
})

const expandedOverviewIcon = computed(() => {
    return state.expandedOverview ? 'angle-up' : 'angle-down'
})

const showSecondaryCta = computed(() => {
    return props.course.secondary_cta_title || props.course.secondary_cta_text || props.course.secondary_cta_button_text || props.course.secondary_cta_url
})

function formatDate(date, format) {
    return moment(date).format(format);
}

</script>
