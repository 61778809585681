<template>
    <button @click.prevent="openModal" class="flex w-full justify-center font-semibold p-15 border border-blue-50 text-blue hover:bg-blue-50 rounded text-center transition-all duration-300">
        Download Course Information
    </button>

    <Teleport to="body">
        <Modal :open="open" @close="closeModal" container-classes="w-full max-w-[600px] max-h-5/6 p-30 p-30 bg-white rounded-md shadow-lg tran2sition-all duration-300">
            <div>
                <p class="mb-30 font-semibold text-2xl">{{ course.title }}</p>
                <p class="p-10 bg-blue-50 rounded font-semibold text-sm text-blue mb-10">Please enter your details below to continue</p>

                <form v-if="openModal" @submit.prevent="submitDownloadEmail" id="download-pdf-form" >
                    <div class="flex flex-col mb-20">
                        <FormLabel>First Name</FormLabel>
                        <ValidationError v-if="state.errors.first_name">{{ state.errors.first_name[0] }}</ValidationError>
                        <TextInput id="download-course-info-first-name-input" name="first_name" placeholder="First Name" class="flex-1" />
                    </div>
                    <div class="flex flex-col mb-20">
                        <FormLabel>Last Name</FormLabel>
                        <ValidationError v-if="state.errors.last_name">{{ state.errors.last_name[0] }}</ValidationError>
                        <TextInput id="download-course-info-last-name-input" name="last_name" placeholder="Last Name" class="flex-1" />
                    </div>
                    <div class="flex flex-col mb-20">
                        <FormLabel>Email address</FormLabel>
                        <ValidationError v-if="state.errors.email">{{ state.errors.email[0] }}</ValidationError>
                        <TextInput id="download-course-info-email-input" name="email" placeholder="Email address" class="flex-1" />
                    </div>
                    <div class="flex flex-col mb-20">
                        <Checkbox v-model="receivesMarketing" id="accepts_marketing_input_download" name="accepts_marketing">Receive marketing communications</Checkbox>
                    </div>
                    <ValidationError v-if="state.error">{{ state.error }}</ValidationError>
                    <div class="mx-auto">
                        <SubmitButton>Download Course Detail <font-awesome-icon :icon="['fal', 'download']" class="ml-5"></font-awesome-icon></SubmitButton>
                    </div>
                </form>
            </div>
        </Modal>
    </Teleport>

</template>

<script setup>

import { reactive, ref } from 'vue'

const receivesMarketing = ref(false)

const state = reactive({
    loading: false,
    errors: {},
    error: false

})

const open = ref(false)

const props = defineProps(['url', 'course'])

function submitDownloadEmail()
{
    let formData = new FormData(document.getElementById('download-pdf-form'))
    state.error = false
    state.errors = {}

    formData.append('pdfUrl', props.url)
    formData.append('courseId', props.course.id)

    state.loading = true

    axios.post(window.location.href, formData, {
        'headers' : {
            'X-OCTOBER-REQUEST-HANDLER' : 'onDownloadPdf',
            'X-Requested-With': 'XMLHttpRequest'
        }
    }).then(response => {
        window.open(response.data.url, "_blank")
        closeModal()
    }).catch(error => {
        if (error.response?.status == 406) {
            state.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
        } else {
            state.error = error.response.data.error
            throw error;
        }
    }).then(() => {
        state.loading = false
    });
}

function closeModal() {
    open.value = false
    document.getElementsByTagName('body')[0].classList.remove('fixed')
    state.errors = false
    state.error = false
}

function openModal() {
    open.value = true
    document.getElementsByTagName('body')[0].classList.add('fixed')
}

</script>