<template>
    <div class="p-20 border-2 border-black shadow-md relative">
        <button
            v-show="isClosable"
            @click.prevent="$emit('cancelled-new-attendee')"
            class="absolute -right-[13px] -top-[13px] w-[26px] h-[26px] border-black border-2 bg-white rounded-full flex items-center justify-center text-sm">
            <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
        </button>
        <form id="newAttendeeForm" @submit.prevent="createAttendee">
            <div v-if="Object.keys(errors).length > 0"
                @click.prevent="errors={}" class="bg-red-50 text-red-500 p-10 rounded mb-10 cursor-pointer">
                <p class="font-bold">Unable to add attendee</p>
                <ValidationError v-if="errors.name" class="text-red-500 text-sm" @click.prevent="errors.name=false">{{ errors.name[0] }}</ValidationError>
                <ValidationError v-if="errors.last_name && !errors.name" class="text-red-500 text-sm" @click.prevent="errors.last_name=false">{{ errors.last_name[0] }}</ValidationError>
                <ValidationError v-if="errors.email" class="text-red-500 text-sm" @click.prevent="errors.email=false">{{ errors.email[0] }}</ValidationError>
                <ValidationError v-if="errors.unique_email" class="text-red-500 text-sm" @click.prevent="errors.unique_email=false">{{ errors.unique_email[0] }}</ValidationError>
                <ValidationError v-if="errors.phone" class="text-red-500 text-sm" @click.prevent="errors.phone=false">{{ errors.phone[0] }}</ValidationError>
            </div>
            <div class="flex flex-col sm:flex-row flex-wrap">
                <div class="flex-1 mb-15 md:mb-0 sm:pr-5 md:pr-10">
                    <TextInput name="name" placeholder="Full name" v-model="attendee.name" class="w-full" autocomplete="off" />
                </div>
                <div class="flex-1 mb-15 md:mb-0 sm:pl-5 md:px-10">
                    <TextInput name="email" placeholder="Email address" v-model="attendee.email" class="w-full" />
                </div>
                <div class="flex-1 mb-15 md:mb-0 sm:pl-5 md:px-10">
                    <TextInput type="text" name="phone" placeholder="Phone number" v-model="attendee.phone" class="w-full" />
                </div>
                <div class="w-full md:w-auto md:flex-shrink md:pl-10">
                    <SubmitButton class="w-full">Add</SubmitButton>
                </div>
            </div>
        </form>

        <div v-if="addExistingError.unique_email"
            @click.prevent="addExistingError.unique_email=false"
            class="bg-red-50 text-red-500 p-10 rounded mt-20 cursor-pointer">
            <p class="font-bold">Unable to add existing attendee</p>
            <ValidationError class="text-red-500 text-sm">
                An attendee with the same email address already exists on the booking.
            </ValidationError>
        </div>

        <div v-if="similarAttendees.length > 0" class="mt-20">
            <p class="p-10 font-bold bg-gray-900 rounded-t text-sm text-white">We found these attendees from your previous bookings</p>
            <div v-for="attendee in similarAttendees" :key="attendee.id"
                class="flex justify-between items-center even:bg-gray-100 p-10 text-sm border border-t-transparent border-gray-300 last:rounded-b hover:border hover:border-gray-900">
                {{ attendee.full_name }} <span class="text-xs">{{  attendee.email }}</span>
                <button @click.prevent="$emit('added-existing-attendee', attendee)" class="bg-green-600 text-white text-xs rounded py-5 px-10 hover:bg-green-500">Add to booking</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                similarAttendees: {},
                attendee: { name: '', email: '', phone: '' },
                saving: false,
                errors: {},
            }
        },
        props: [
            'isClosable',
            'searchExcludes',
            'orderId',
            'addExistingError'
        ],
        watch: {
            attendee: {
                handler(attendee, oldAttendee) {
                    if (attendee.name.length >= 3 || attendee.email.length >= 3) {
                        this.searchExistingAttendees()
                    }
                },
                deep: true
            }
        },
        methods: {
            createAttendee() {
                this.saving = true
                this.errors = {}

                let attendeeForm = document.getElementById('newAttendeeForm')
                let formData = new FormData(attendeeForm)

                formData.append('orderId', this.orderId)

                // axios post request to create attendee for currently logged in user!
                axios.post(window.location.href, formData, {
                    'headers': {
                        'X-OCTOBER-REQUEST-HANDLER': 'onCreateAttendee',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    attendeeForm.reset()
                    this.$emit('created-attendee', response.data.attendee)
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.error = error.response.data
                        throw error;
                    }
                }).then(() => {
                    this.saving = false
                })
            },

            searchExistingAttendees() {
                axios.post(window.location.href, {
                        attendee: this.attendee,
                        excludes: this.searchExcludes
                    }, {
                    'headers': {
                        'X-OCTOBER-REQUEST-HANDLER' : 'onSearchAttendees',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    this.similarAttendees = response.data.existingAttendees
                }).catch(error => {

                }).then(() => {

                })
            }
         }
    }
</script>