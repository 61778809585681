<template>
    <div id="manage_attendees">
        <div class="flex flex-col md:flex-row md:items-center md:justify-between mb-10 sm:mb-15 md:mb-20 lg:mb-30 xl:mb-40 2xl:mb-50">
            <p class="text-xl font-bold mb-10 sm:mb-15 md:mb-0">Manage Saved Attendees</p>
            <div>
                <button v-if="!state.attendeeFormOpen && !state.displayOnlyMode" @click.prevet="toggleNewAttendeeForm" id="new_attendee_button" type="button" class="p-10 bg-black text-white font-semibold rounded">
                    <font-awesome-icon :icon="['fal', 'plus']" class="mr-5"></font-awesome-icon>Add a new attendee
                </button>
                <button v-if="state.attendeeFormOpen" @click.prevent="toggleNewAttendeeForm" id="new_attendee_cancel_button" type="button" class="p-10 bg-black text-white font-semibold rounded">
                    <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon> {{ state.attendeeFormMode === "new" ? 'Cancel' : 'Close' }}
                </button>
            </div>
        </div>

        <AccountAttendeeSearch v-if="allowSearch" v-on:attendeeAdded="getAttendees(), state.attendeeFormOpen = false" />


        <form v-if="state.attendeeFormOpen" id="attendee_form" @submit.prevent="saveAttendee" method="POST" class="bg-gray-50 border border-gray-200 rounded-lg p-30 mb-50">
            <h4 class="mb-30 text-2xl font-semibold" v-show="state.attendeeFormMode == 'new'">Add a new attendee</h4>
            <h4 class="mb-30 text-2xl font-semibold" v-show="state.attendeeFormMode == 'edit'">Edit attendee</h4>

            <div class="grid gap-10 md:gap-20 lg:gap-30 grid-cols-1 md:grid-cols-2 mb-10 md:mb-20 lg:mb-30">
                <div class="flex flex-col" v-bind:class="{ error: state.formErrors.first_name }">
                    <label for="first_name"><font-awesome-icon :icon="['fal', 'id-card']" fixed-width class="mr-10 text-orange"/>First Name <span>*</span></label>
                    <TextInput name="first_name" id="first_name" v-model="state.attendeeModel.first_name" />
                    <p v-if="state.formErrors.first_name" class="text-xs text-red-500">{{ state.formErrors.first_name[0] }}</p>
                </div>
                <div class="flex flex-col" v-bind:class="{ error: state.formErrors.last_name }">
                    <label for="last_name"><font-awesome-icon :icon="['fal', 'tag']" fixed-width class="mr-10 text-orange"/>Last Name <span>*</span></label>
                    <TextInput name="last_name" id="last_name" v-model="state.attendeeModel.last_name" />
                    <p v-if="state.formErrors.last_name" class="text-xs text-red-500">{{ state.formErrors.last_name[0] }}</p>
                </div>
            </div>
            <div class="grid gap-10 md:gap-20 lg:gap-30 grid-cols-1 md:grid-cols-2 mb-30">
                <div class="flex flex-col" v-bind:class="{ error: state.formErrors.email }">
                    <label for="email-input"><font-awesome-icon :icon="['fal', 'envelope']" fixed-width class="mr-10 text-orange"/>Email address <span>*</span></label>
                    <TextInput name="last_name" id="email-input" v-model="state.attendeeModel.email" />
                    <p v-if="state.formErrors.email" class="text-xs text-red-500">{{ state.formErrors.email[0] }}</p>
                </div>
                <div class="flex flex-col" v-bind:class="{ error: state.formErrors.phone }">
                    <label for="phone-input"><font-awesome-icon :icon="['fal', 'phone']" fixed-width class="mr-10 text-orange"/>Phone number</label>
                    <TextInput name="last_name" id="phone-input" v-model="state.attendeeModel.phone" />
                    <p v-if="state.formErrors.phone" class="text-xs text-red-500">{{ state.formErrors.email[0] }}</p>
                </div>
            </div>

            <button class="bg-orange text-white font-semibold rounded p-10">Save <font-awesome-icon :icon="saveAttendeeIcon" v-bind:spin="state.loading" /></button>
        </form>

        <div id="attendee_list">
            <template v-for="attendee in state.attendees">
                <div class="flex flex-wrap flex-col md:flex-row md:items-center rounded-lg bg-gray-50 border border-gray-200 p-10">
                    <div class="md:w-1/2 lg:w-1/3">
                        {{ attendee.full_name }}
                    </div>
                    <div class="md:w-1/2 lg:w-auto lg:flex-1">
                        {{ attendee.email }}
                    </div>
                    <div class="md:w-full lg:w-auto lg:flex-shrink pt-10 lg:pt-0">
                        <template v-if="!displayOnlyMode">
                            <button v-show="!attendee.removeConfirm" v-on:click="attendee.removeConfirm = true"
                                class="p-10 bg-red-500 text-white font-semibold text-xs rounded">
                                <font-awesome-icon :icon="['fal', 'trash']" class="mr-5" fixed-width />Remove
                            </button>

                            <template v-if="state.loading">
                                <button v-show="attendee.removeConfirm" v-on:click="removeAttendee(attendee)"
                                    class="p-10 bg-gray-100 font-semibold text-xs rounded">
                                    <font-awesome-icon :icon="['fal', 'spinner-third']" spin fixed-width class="mr-5" />
                                    Removing
                                </button>
                            </template>
                            <template v-else>
                                <button v-show="attendee.removeConfirm" v-on:click="removeAttendee(attendee)" class="p-10 bg-red-500 text-white font-semibold text-xs rounded">
                                    <font-awesome-icon :icon="['fal', 'trash']" fixed-width class="mr-5" />Confirm
                                </button>
                                <button v-show="attendee.removeConfirm" v-on:click="attendee.removeConfirm = false" class="p-10 bg-black text-white font-semibold text-xs rounded ml-10">
                                    <font-awesome-icon :icon="['fal', 'times']" fixed-width class="mr-5" />Cancel
                                </button>
                            </template>

                            <button v-on:click="prepareAttendeeEdit(attendee)" class="p-10 bg-blue text-white font-semibold text-xs rounded ml-10">
                                <font-awesome-icon :icon="['fal', 'pencil']" fixed-width class="mr-5"/> Edit
                            </button>
                        </template>
                    </div>
                </div>
            </template>
        </div>
        <p v-show="!state.attendees || state.attendees.length == 0" class="text-center" style="margin:1rem;">Click the button above to add a new attendee.</p>
    </div>
</template>

<script setup>

import { reactive, computed, onMounted } from 'vue';
import AccountAttendeeSearch from './AccountAttendeeSearch.vue';

const state = reactive({
    attendeeModel: false,
    attendeeFormMode: 'new',
    attendeeFormOpen: false,
    attendees: false,
    generalError: false,
    formErrors : false,
    loading: false
})

const props = defineProps([
    'user',
    'displayOnlyMode',
    'allowSearch'
])

onMounted(() => {
    getAttendees()
    clearForm()
})

const saveAttendeeIcon = computed(() => {
    if (state.loading) {
        return ['fal', 'spinner-third'];
    }
    else {
        return ['fal', 'plus'];
    }
})

function toggleNewAttendeeForm() {
    state.attendeeFormOpen = !state.attendeeFormOpen
    state.attendeeFormMode = 'new'
    clearForm();
}

function getAttendees() {
    state.loading = true;
    axios.post(window.location.href, {}, { headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onGetAttendees', 'X-Requested-With': 'XMLHttpRequest' }})
    .then(response => {
        state.loading = false;
        state.attendees = response.data;
        if (!state.attendees || state.attendees.length == 0) {
            seedUserAsAttendee()
        }
    })
    .catch(e => {
        state.loading = false;
        state.attendees = false;
    });
}

function saveAttendee() {
    state.loading = true;
    state.formErrors = false;

    axios({
        method: 'post',
        url: window.location.href,
        data: state.attendeeModel,
        headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onSaveAttendee', 'X-Requested-With': 'XMLHttpRequest' }
    })
    .then (response => {
        state.attendeeFormOpen = false;
        state.attendeeModel = false;
        getAttendees();
    })
    .catch(e => {
        state.loading = false;
        if (e.response?.status == 406) {
            state.formErrors = e.response.data.X_OCTOBER_ERROR_FIELDS;
        }
        else {
            state.generalError = e.message;
            throw e;
        }
    });
}

function clearForm() {
    state.attendeeModel = {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
    }
}

function removeAttendee(attendee) {
    state.loading = true;
    axios({
        method: 'post',
        url: window.location.href,
        data: { id : attendee.id },
        headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onRemoveAttendee', 'X-Requested-With': 'XMLHttpRequest' }
    })
    .then (response => {
        state.loading = false;
        getAttendees();
    })
    .catch(e => {
        state.loading = false;
    });
}

function prepareAttendeeEdit(attendee) {
    state.attendeeFormOpen = true;
    state.attendeeFormMode = 'edit';
    state.attendeeModel = attendee;
}

function seedUserAsAttendee() {
    state.attendeeFormOpen = true;
    state.attendeeFormMode = 'new';
    state.attendeeModel = {
        first_name: props.user.name,
        last_name: props.user.surname,
        email: props.user.email,
        phone: props.user.phone,
    }
}

</script>