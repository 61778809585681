<template>
    <div id="attendee_search">
        <font-awesome-icon :icon="['fal', 'search']" />
        <input v-model="searchterm" type="text" id="attendee_search_input" placeholder="Search previous attendees" />
        <button v-if="results || loading" v-on:click="clearInput" class="clear" type="button"><font-awesome-icon :icon="clearIcon" :spin="loading" /></button>
        <div class="results">
            <ul>
                <li v-for="result in results">
                    <a v-on:click="addAttendee(result)">{{ result.first_name }} {{ result.last_name }}</a>
                    <button v-show="!result.loading" type="button" class="button rhs small blue"><font-awesome-icon :icon="['fal', 'plus-circle']" /> Add</button>
                    <button v-show="result.loading" type="button" class="rhs loading"><font-awesome-icon :icon="['fal', 'spinner-third']" spin /></button>
                </li>
                <li v-if="results.length == 0">
                    <p class="text-center">No matches</p>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import debounce from 'lodash/debounce';

    export default {
        data: function() {
            return {
                searchterm: "",
                results: false,
                loading: false,
                excludes: this.initialExcludes
            }
        },
        watch: {
            searchterm: function() {
                this.search();
            },
            initialExcludes(excludes) {
                this.excludes = excludes;
            }
        },
        props: [
            'initialExcludes'
        ],
        computed: {
            clearIcon: function() {
                if (this.loading) {
                    return ['fal', 'spinner-third'];
                }
                else if (this.results) {
                    return ['fal', 'times'];
                }
                else {
                    return false;
                }
            }
        },
        methods: {
            search() {

            },

            // search: _.debounce (function(){
            //     if (this.searchterm.length == 0) {
            //         this.results = false;
            //     }
            //     else {
            //         this.loading = true;
            //         this.selectedResult = -1;
            //         var vueModel = this;
            //         axios({
            //             method: 'post',
            //             url: window.location.href,
            //             data: {
            //                 searchterm: this.searchterm,
            //                 excludes: this.excludes
            //             },
            //             headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onSearchAttendees', 'X-Requested-With': 'XMLHttpRequest' }
            //         }).then(response => {
            //             this.loading = false;
            //             this.results = response.data
            //         }).catch(e => {
            //             this.loading = false;
            //         });
            //     }
            // }, 500),

            clearInput: function() {
                this.searchterm = "";
                this.results = false;
            },
            addAttendee: function(attendee) {
                this.clearInput();
                this.$emit('clicked', attendee);
            }
        }
    }
</script>