<template>
    <div id="account_dashboard">
        <h1 class="text-xl font-bold mb-50">Dashboard</h1>
        <div class="grid gap-20 md:gap-30 lg:gap-40 2xl:gap-50 grid-cols-1 sm:grid-cols-2 2xl:grid-cols-4">
            <a v-for="item in items" :href="item.url" class="text-center rounded bg-white shadow hover:bg-gray-50/50 p-20 md:p-30 2xl:p-50 flex flex-col items-center justify-center transition-all duration-300">
                <font-awesome-icon :icon="item.icon" class="text-orange text-xl md:text-2xl 2xl:text-4xl mb-30"></font-awesome-icon>
                <span class="text-xs md:text-sm lg:text-base font-semibold">{{ item.label }}</span>
            </a>
        </div>
    </div>
</template>

<script setup>

let items = [
{
        url: "/account/profile",
        icon: ['fal', 'pencil'],
        label: "Edit profile"
    },
    {
        url: "/account/password",
        icon: ['fal', 'lock'],
        label: "Change password"
    },
    {
        url: "/account/bookings",
        icon: ['fal', 'list'],
        label: "Course bookings"
    },
    {
        url: "/account/attendees",
        icon: ['fal', 'users'],
        label: "Manage attendees"
    },
]

</script>