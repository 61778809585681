<template>
    <div>
        <form @submit.prevent="prePayment" id="paymentForm" class="flex flex-col items-center mb-30">
            <div class="mx-auto w-full max-w-[700px] border border-gray-200 rounded-lg mb-30 overflow-hidden">
                <div class="bg-gray-50 p-20 border-b border-gray-200 rounded-t-lg font-semibold text-lg">
                    Payment
                </div>
                <div v-if="order" class="bg-white rounded-br rounded-bl">
                    <input type="hidden" name="billing_address" v-model="order.billing_address" />
                    <input type="hidden" name="certificate_address" v-model="order.certificate_address" />
                    <div class="flex flex-col md:flex-row">
                        <div class="md:flex-1 border-b md:border-b-0">
                            <div class="border-b p-20">
                                <p class="text-orange font-semibold mb-10">Billing Address</p>
                                <ValidationError v-if="errors.billing_address" @click.prevent="errors.billing_address=false" class="cursor-pointer bg-red-500 text-white p-5 rounded mb-5">{{ errors.billing_address[0] }}</ValidationError>
                                <checkout-address :current-address="order.billing_address" @addressUpdated="setBillingAddress">
                                    <template v-slot:success>
                                        <p v-if="billingAddressChangedSuccess" class="text-green-600 font-semibold">
                                            <font-awesome-icon :icon="['fal', 'check-circle']" class="mr-5"/>Saved
                                        </p>
                                    </template>
                                </checkout-address>
                            </div>
                            <div class="border-b p-20">
                                <p class="text-orange font-semibold mb-10">Certificate Address</p>
                                <ValidationError v-if="errors.certificate_address" @click.prevent="errors.certificate_address=false" class="cursor-pointer">{{ errors.certificate_address[0] }}</ValidationError>
                                <Checkbox id="certificate_billing_same_address" type="checkbox" v-model="certificateSameAsBilling" :class="{ 'mb-10' : !certificateSameAsBilling }" theme="light">Same as billing address</Checkbox>
                                <checkout-address v-show="!certificateSameAsBilling" :current-address="order.certificate_address" @addressUpdated="setCertificateAddress">
                                    <template v-slot:success>
                                        <p v-if="certificateAddressChangedSuccess" class="text-green-600 font-semibold">
                                            <font-awesome-icon :icon="['fal', 'check-circle']" class="mr-5"/>Saved
                                        </p>
                                    </template>
                                </checkout-address>
                            </div>
                            <div class="border-b p-20">
                                <p class="text-orange font-semibold mb-10">Joining Instructions</p>
                                <ValidationError v-if="errors.ji_send_to" @click.prevent="errors.ji_send_to=false" class="cursor-pointer">{{ errors.ji_send_to[0] }}</ValidationError>
                                <div class="flex flex-col gap-10">
                                    <RadioInput v-model="sendJoiningInstructions" id="send-ji-to-booker-input" name="ji_send_to" value="booker_only" theme="light">Email to booker</RadioInput>
                                    <RadioInput v-model="sendJoiningInstructions" id="send-ji-to-booker-attendees-input" name="ji_send_to" value="booker_and_attendees" theme="light">Email to booker and attendees</RadioInput>
                                </div>
                            </div>
                            <div class="border-b p-20">
                                <p class="text-orange font-semibold mb-10">Payment Method</p>
                                <ValidationError v-if="errors.payment_type" @click.prevent="errors.payment_type=false" class="cursor-pointer">{{ errors.payment_type[0] }}</ValidationError>

                                <div class="flex flex-col gap-10">
                                    <RadioInput v-model="paymentType" id="payment-card-input" name="payment_type" value="card" theme="light">Card Payment</RadioInput>
                                    <RadioInput v-if="paylaterEnabled" v-model="paymentType" id="payment-payl8r-input" name="payment_type" value="payl8r" theme="light">Pay with <span class="ml-5 bg-black rounded px-[8px]"><img src="/images/payl8r-logo.png" alt="Payl8r logo" class="inline w-[44px] h-[16px]" /></span></RadioInput>
                                    <RadioInput v-if="payByInvoiceEnabled" v-model="paymentType" id="payment-invoice-input" name="payment_type" value="invoice" theme="light">Invoice / Purchase order</RadioInput>
                                </div>

                                <div v-if="paymentType == 'invoice'" class="pt-10">
                                    <ValidationError v-if="errors.po_number" @click.prevent="errors.po_number=false" class="cursor-pointer">{{ errors.po_number[0] }}</ValidationError>
                                    <FormLabel for="po_number_input">Purchase order number</Formlabel>
                                    <TextInput id="po_number_input" name="po_number" class="w-full" />
                                </div>
                            </div>
                            <div class="p-20">
                                <ValidationError v-if="errors.terms_agreed" @click.prevent="errors.terms_agreed=false" class="cursor-pointer">{{ errors.terms_agreed[0] }}</ValidationError>
                                <Checkbox v-model="termsAccepted" id="agree-terms-input" name="terms_agreed" theme="light" class="mb-10">I agree to the ESS terms and conditions<br></Checkbox>
                                <a class="text-sm text-orange font-semibold hover:underline" href="/booking-terms-conditions" target="_blank">View terms and conditions in a new tab<font-awesome-icon :icon="['fal', 'arrow-up-right-from-square']" class="ml-5 mb-[1px] text-xs"></font-awesome-icon></a>
                            </div>
                        </div>
                        <div class="md:w-[250px] p-30 md:border-l">
                            <p class="text-orange font-semibold mb-10">Pricing</p>
                            <div class="flex text-sm mb-10">
                                <div class="w-2/3">Attendees</div>
                                <div class="flex-1 text-right">{{ initialOrder.attendees.length }}</div>
                            </div>
                            <div class="flex text-sm mb-10">
                                <div class="w-2/3">Price per person</div>
                                <div class="flex-1 text-right">£{{ initialOrder.pricing.formatted_price_per_attendee }}</div>
                            </div>
                            <div class="flex text-sm mb-10">
                                <div class="w-2/3">Total Price</div>
                                <div class="flex-1 text-right">£{{ initialOrder.pricing.formatted_total_price }}</div>
                            </div>
                            <div class="flex text-sm mb-10">
                                <div class="w-2/3">Subtotal</div>
                                <div class="flex-1 text-right">£{{ initialOrder.pricing.formatted_subtotal }}</div>
                            </div>
                            <div class="flex text-sm mb-10">
                                <div class="w-2/3">VAT</div>
                                <div class="flex-1 text-right">£{{ initialOrder.pricing.formatted_vat }}</div>
                            </div>
                            <div class="flex font-bold text-sm mb-10 border-t-2 border-black pt-10">
                                <div class="w-2/3">Total</div>
                                <div class="flex-1 text-right text-pink">£{{ initialOrder.pricing.formatted_total }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="Object.keys(errors).length > 0" class="mx-auto w-full max-w-[700px] mb-30">
                <div class="bg-yellow-100 border-l-2 border-yellow-500 text-yellow-800 px-20 py-10 rounded">
                    Please check the form for any errors and try again.
                </div>
            </div>
            <SubmitButton>Continue
            </SubmitButton>
        </form>
        <form v-if="paylaterEnabled" id="paylater_form" ref="paylaterForm" action="https://payl8r.com/process" method="post">
            <input type="hidden" name="data" :value="encryptedData">
            <input type="hidden" name="rid" :value="paylaterUsername">
        </form>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {

        data() {
            return {
                order: null,
                errors: {},
                showPricing: true,
                certificateSameAsBilling: true,
                sendJoiningInstructions: 'booker_only',
                paymentType: 'card',
                billingAddressChangedSuccess: false,
                certificateAddressChangedSuccess: false,
                termsAccepted: false,
            }
        },

        mounted() {
            this.order = this.initialOrder
            this.show = false

            if (!this.order.certificate_address && this.order.billing_address && this.certificateSameAsBilling) {
                this.setCertificateAddress(this.order.billing_address);
            }
        },

        props: {
            initialOrder: {
                type: Object,
                default: {}
            },
            paylaterUsername: {
                type: String,
                default: ''
            },
            paylaterEnabled: {
                type: Boolean,
                default: false
            },
            payByInvoiceEnabled: {
                type: Boolean,
                default: false
            },
            encryptedData: {
                default: false
            },
            stripeKey: {
                type: String,
                default: ''
            },
            stripeSessionId: {
                type: String,
                default: ''
            }
        },

        watch: {
            certificateSameAsBilling(newValue, oldValue) {
                if (newValue == true) {
                    this.setCertificateAddress(this.order.billing_address);
                }
            }
        },

        methods: {

            setBillingAddress(addressString) {

                this.billingAddress = addressString;
                axios.post(window.location.href, {
                    orderId: this.order.id,
                    addressString: addressString
                }, {
                    'headers' : {
                        'X-OCTOBER-REQUEST-HANDLER' : 'onSetBillingAddress',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    this.order = response.data.order
                    if (this.certificateSameAsBilling) {
                        this.setCertificateAddress(addressString)
                    }
                    this.billingAddressChangedSuccess = true
                    this.hideBillingAddressSuccess()

                }).catch(error => {
                }).then(() => {
                });
            },

            hideBillingAddressSuccess() {
                setTimeout(() => this.billingAddressChangedSuccess = false, 4000);
            },

            setCertificateAddress(addressString) {
                this.certificateAddress = addressString;
                axios.post(window.location.href, {
                    orderId: this.order.id,
                    addressString: addressString
                }, {
                    'headers' : {
                        'X-OCTOBER-REQUEST-HANDLER' : 'onSetCertificateAddress',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    this.order = response.data.order
                    this.certificateAddressChangedSuccess = true
                    this.hideCertificateAddressSuccess()
                }).catch(error => {
                }).then(() => {
                });
            },

            hideCertificateAddressSuccess() {
                setTimeout(() => this.certificateAddressChangedSuccess = false, 4000);
            },

            prePayment() {
                this.errors = {}
                let paymentFormData = new FormData(document.getElementById('paymentForm'))
                paymentFormData.append('order_id', this.order.id)

                axios({
                    method: 'post',
                    url: window.location.href,
                    data: paymentFormData,
                    headers: {
                        'X-OCTOBER-REQUEST-HANDLER': 'onPrePayment',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                })
                .then(response => {
                    if (response.data == "make_stripe_payment") {
                        this.redirectToCheckout();
                    }

                    if (response.data == "make_paylater_request") {
                        this.$refs.paylaterForm.submit();
                    }

                    if (response.data.redirect) {
                        window.location = response.data.redirect
                    }
                })
                .catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        throw error;
                    }
                })
                .then(resp => {

                });
            },

            redirectToCheckout() {
                const stripe = Stripe(this.stripeKey);

                stripe.redirectToCheckout({
                    sessionId: this.stripeSessionId
                })
                .then(function(result) {
                    // handle errors some how
                    // result.error.message
                });
            },

            poSubmit() {
                if (this.poNumber === null) {
                    this.poError = true;
                } else {
                    this.loading = true;
                    this.prePayment();
                }
            },

            formatDate(date) {
                return moment(date).format('Do MMM Y');
            },

            formatTime(time) {
                return moment(time).format('h:mm a');
            },
        }
    }
</script>