<template>
    <a :href="result.slug" class="relative bg-white border border-gray-50 rounded shadow-none hover:shadow-md p-20 group overflow-hidden transition-all duration-300">
        <p class="font-bold">{{ result.title }}</p>
        <div v-if="hasDetails" v-show="showDetails" class="pt-15">
            <div v-if="(result.duration || result.type) && result.type != 'Apprenticeship' " class="text-xs">
                <div v-if="result.duration"><span class="font-semibold mr-5">Duration:</span>{{ result.duration }}</div>
                <div v-if="result.type"><span class="font-semibold mr-5">Type:</span>{{ result.type }}</div>
            </div>
            <div v-if="result.accreditation && result.type != 'Apprenticeship'" class="text-xs">
                <span class="font-semibold">Accreditation:</span> {{ result.accreditation }}
            </div>
            <div v-if="result.search_snippet" class="text-xs pt-10">
                <label for="who-should-attend" v-if="result.type != 'Apprenticeship'">Who should attend?</label>
                {{ result.search_snippet }}
            </div>
        </div>
        <div v-if="hasExtras" class="flex flex-col items-start gap-10 -ml-5 pt-20">
            <div v-show="result.bookable_online" class="py-5 px-15 rounded-full bg-blue-50 font-semibold text-xs text-blue">Bookable online</div>
            <div v-show="result.grant_available" class="py-5 px-15 rounded-full bg-green-50 font-semibold text-xs text-green-500">CITB Qualification Grant Available</div>
        </div>
        <span class="opacity-0 w-25 h-30 bottom-20 right-40 group-hover:right-10 group-hover:opacity-100 text-lg absolute text-orange transition-all duration-300 "><font-awesome-icon :icon="['fal', 'arrow-right']" fixed-width></font-awesome-icon></span>
    </a>
</template>

<script>
    export default {
        data() {
            return {
                showDetails: true
            }
        },
        props: ['result'],
        computed: {
            hasDetails() {
                return this.result.duration || this.result.type || this.result.accreditation || this.result.search_snippet;
            },
            hasExtras() {
                return this.result.bookable_online || this.result.grant_available;
            }
        }
    }
</script>