<template>
    <div class="pt-10">
        <template v-if="!resetCode && !requestSent">
            <form @submit.prevent="resetPasswordRequest" class="">
                <p class="mb-20">Enter your email address below and we'll send you a link to reset your password.</p>
                <p v-if="requestError" class="text-red-500 my-5 mb-30">{{ requestError }}</p>
                <div class="flex flex-col">
                    <FormLabel for="email-input" >Email address</FormLabel>
                    <div class="flex flex-col sm:flex-row">
                        <TextInput v-model="email" id="email-input" type="email" name="email" placeholder="Email address" class="flex sm:flex-1 mb-10 sm:mb-0 sm:mr-10" />
                        <div class="sm:w-[192px] flex justify-center items-center flex-nowrap">
                            <font-awesome-icon v-if="sending" :icon="['fal', 'spinner-third']" spin />
                            <SubmitButton v-else type="submit" class="px-20 py-[15px] leading-none whitespace-nowrap">Restore Password</SubmitButton>
                        </div>
                    </div>
                    <p v-if="errors.email" @click="errors.email=false">{{ errors.email[0] }}</p>
                </div>
            </form>
        </template>

        <form v-else @submit.prevent="resetPassword" class="">
            <h1 class="text-lg font-semibold mb-20 block">Create new password</h1>
            <template v-if="requestSent">
                <p class="mb-5">We have sent you an email with a code. <a @click.prevent="requestSent = false" class="text-blue font-semibold underline cursor-pointer">Send another</a>.</p>
                <p class="mb-30">Enter the code below and choose a new password.</p>
            </template>
            <p v-if="resetError" class="text-red-500">{{ resetError }}</p>
            <div class="flex flex-col mb-20">
                <FormLabel for="code-input">Code</FormLabel>
                <TextInput v-model="code" id="code-input" name="code" />
                <ValidationError v-if="errors.code">{{ errors.code[0] }}</ValidationError>
            </div>
            <div class="flex flex-col mb-20">
                <label for="password-input" class="text-sm font-bold">New password</label>
                <div class="flex flex-col sm:flex-row">
                    <PasswordInput v-model="password" id="password-input" name="password" class="flex-1 mb-10 sm:mb-0 sm:mr-10"/>
                    <div class="w-full sm:w-[192px] flex justify-center items-center">
                        <font-awesome-icon v-if="sending" :icon="['fal', 'spinner-third']" spin />
                        <SubmitButton v-else @click.prevent="resetPassword" class="w-full">Update Password</SubmitButton>
                    </div>
                </div>
                <ValidationError v-if="errors.password">{{ errors.password[0] }}</ValidationError>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                email: '',
                code: '',
                password: '',
                sending: false,
                requestSent: false,
                errors: {},
                requestError: false,
                resetError: false,
                codeInputDisabled: false
            }
        },

        mounted() {
            this.code = this.resetCode;
        },

        props: {
            resetCode: {
                type: [String],
                default: ''
            },

            redirectUrl: {
                type: [String,Boolean],
                default: false
            }
        },

        methods: {

            /**
             *
             * Request password update and send code to user email
             *
             */
            resetPasswordRequest() {
                this.sending = true
                this.errors = {}
                this.requestError = false

                axios.post(window.location.href, {
                    email: this.email,
                }, {
                    headers: { 'X-OCTOBER-REQUEST-HANDLER' : 'onResetPasswordRequest' }
                }).then(response => {
                    this.requestSent = true
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.requestError = error.response.data
                        throw error;
                    }
                }).then(() => {
                    this.sending = false
                })
            },

            /**
             *
             * Verify the given code and update password
             *
             */
            resetPassword() {
                this.sending = true
                this.errors = {}
                this.resetError = false

                axios.post(window.location.href, {
                    code: this.code,
                    password: this.password,
                    redirectUrl: this.redirectUrl
                }, {
                    headers: { 'X-OCTOBER-REQUEST-HANDLER' : 'onResetPassword' }
                }).then(response => {
                    if (response.data.redirectUrl) {
                        window.location.replace(response.data.redirectUrl);
                    }

                    this.$emit('password-reset-successfully', response.data.success);
                    this.requestSent = false
                    this.resetError = false
                    this.code = ""
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.resetError = error.response.data
                        throw error;
                    }
                }).then(() => {
                    this.sending = false
                })
            },
        }
    }
</script>