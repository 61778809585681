<template>
    <button @click.prevent="logout" :class="buttonClass" class="transition duration-300">
        <font-awesome-icon v-if="this.loading" :icon="['fal', 'circle-notch']" spin />
        <template v-else>
            <font-awesome-icon :icon="['fal', 'sign-out']" :class="iconClass" fixed-width/>
            <span class="inline-block">Logout</span>
        </template>
    </button>
</template>

<script>
    export default {
        data() {
            return {
                loading: false
            }
        },

        props: {
            buttonClass: {
                type: String,
                default: 'text-white hover:text-secondary'
            },
            iconClass: {
                type: String,
                default: ''
            }
        },

        methods: {
            logout() {
                this.loading = true
                axios.post('/logout', null, {
                    headers: {
                        'X-OCTOBER-REQUEST-HANDLER' : 'onLogout'
                    }
                }).then(response => {
                    window.location.replace('/login');
                }).catch(error => {
                    this.loading = false
                })
            }
        }
    }
</script>