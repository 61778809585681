<template>
    <li v-if="showItem" :class="listItemClasses">
        <a :href="item.url" :class="linkClasses">
            {{ item.title }}
        </a>
        <button v-if="itemHasItems" :class="buttonClasses"
            @click.prevent="dropdownOpen = !dropdownOpen">
            <font-awesome-icon :icon="dropdownIcon" fixed-width ></font-awesome-icon>
        </button>
        <nav-list v-if="itemHasItems"
            :nav-items="item.items"
            :list-class="dropdownClasses"
            :user-logged-in="userLoggedIn">
        </nav-list>
    </li>
</template>

<script>
    export default {
        data() {
            return {
                dropdownOpen: false,
                windowWidth: window.innerWidth
            }
        },

        mounted() {
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth
            });
        },

        props: {
            item: {
                type: Object,
                default: {}
            },
            topLevel: {
                type: Boolean,
                default: false
            },
            userLoggedIn: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            showItem() {
                if ((this.item.viewBag.membersOnly == 1) && !this.userLoggedIn) {
                    return false;
                }

                if ((this.item.viewBag.guestsOnly == 1) && this.userLoggedIn) {
                    return false;
                }

                if (this.item.viewBag.isHidden == 1) {
                    return false;
                }

                return true;
            },

            itemHasItems() {
                return this.item.items.length > 0
            },

            listItemClasses() {
                return {
                    'flex flex-wrap relative group': true,
                    'is-active' : this.dropdownOpen,
                    'parent' : this.itemHasItems,
                    'lg:flex lg:flex-nowrap lg:items-stretch lg:relative' : this.topLevel,
                    'lg:border-b lg:border-primary last:border-b-0' : !this.topLevel
                }
            },

            linkClasses() {
                return {
                    'flex-grow lg:flex-shrink p-15 lg:py-20 lg:px-10 xl:px-15' : true,
                    'font-bold lg:font-normal bg-gray-900 text-white' : this.dropdownOpen,
                    'lg:flex lg:flex-grow lg:items-center lg:text-white lg:leading-none lg:group-hover:bg-gray-900' : this.topLevel,
                    'lg:pr-40 xl:pr-[45px] flex-shrink' : this.itemHasItems && this.topLevel,
                    'pl-30 bg-gray-50 lg:bg-white lg:flex-grow text-primary lg:hover:text-white lg:hover:bg-orange-500  lg:whitespace-wrap' : !this.topLevel,
                    'flex-1' : this.itemHasItems,
                    '' : this.dropdownOpen && this.topLevel
                }
            },

            buttonClasses() {
                return {
                    'submenu-toggle cursor-pointer outline-none rounded-none text-primary py-0 px-20 lg:px-10 xl:px-15' : true,
                    'lg:text-white lg:-ml-40 xl:-ml-[45px] lg:px-10' : this.topLevel,
                    'bg-gray-100 lg:bg-white border-none px-0 py-10' : !this.topLevel,
                    'bg-gray-100 lg:bg-white' : this.dropdownOpen && !this.topLevel,
                    'bg-black lg:bg-transparent text-white' : this.dropdownOpen && this.topLevel
                }
            },

            dropdownClasses() {
                return {
                    'block' : this.dropdownOpen,
                    'hidden' : !this.dropdownOpen,
                    'dropdown relative w-full lg:absolute lg:left-0': true,
                    '' : this.itemHasItems,
                }
            },

            dropdownIcon() {
                if (this.windowWidth < 1024) {
                    return this.dropdownOpen ? ['fal', 'minus'] : ['fal', 'plus']
                }

                if (!this.topLevel) {
                    return ['fal', 'angle-right']
                } else {
                    return ['fal', 'angle-down']
                }
            }
        }
    }
</script>