<template>
    <div :id="'booking_instance_id_'+booking.id" class="overflow-hidden rounded-lg mb-10 border border-gray-200">
        <div class="lg:flex items-center bg-gray-50 p-20">
            <div class="flex-1 flex flex-col mb-10 lg:mb-0">
                <h4 class="font-bold">{{ state.bookingInstance.course_instance.course.title }}</h4>
                <p class="text-xs">Start date: {{ formatDate(state.bookingInstance.course_instance.start_date) }}</p>
            </div>
            <div class="lg:w-1/5 lg:text-center flex items-center mb-10 lg:mb-0 lg:justify-center">
                <p :class="statusClasses">{{ $filters.snakeToTitle(state.bookingInstance.status) }}</p>
            </div>
            <button v-on:click="state.bookingInstance.open = !state.bookingInstance.open" class="flex-shrink px-10 py-5 rounded bg-black text-white font-semibold text-xs">
                Details <font-awesome-icon :icon="['fal', toggleIcon]" class="ml-5"></font-awesome-icon>
            </button>
        </div>

        <div v-if="state.bookingInstance.open" class="border-t border-gray-200 overflow-hidden" >
            <div class="grid grid-cols-1 lg:grid-cols-3 lg:border-b border-gray-50 overflow-hidden">
                <div class="flex p-15">
                    <font-awesome-icon :icon="['fal', 'users']" fixed-width class="text-orange pt-5 mr-10" />
                    <div>
                        <p class="font-semibold">Attendees</p>
                        <ul>
                            <li :key="attendee.id" v-for="attendee in state.bookingInstance.attendees">
                                <span>{{ attendee.first_name }} {{ attendee.last_name }}</span>
                                <a v-if="getCertificateDownloadUrl(booking.id, attendee)" :href="'/download/certificate/'+getCertificateDownloadUrl(booking.id, attendee)">
                                    <font-awesome-icon :icon="['fal', 'download']"></font-awesome-icon> Certificate
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="flex p-15">
                    <font-awesome-icon :icon="['fal', 'building']" fixed-width class="text-orange pt-5 mr-10" />
                    <div>
                        <p class="font-semibold">Venue</p>
                        <p>{{ state.bookingInstance.course_instance.venue.address_1 }}</p>
                        <p>{{ state.bookingInstance.course_instance.venue.address_2 }}</p>
                        <p>{{ state.bookingInstance.course_instance.venue.city }}</p>
                        <p>{{ state.bookingInstance.course_instance.venue.postcode }}</p>
                    </div>
                </div>
                <div class="flex p-15">
                    <font-awesome-icon :icon="['fal', 'calendar']" fixed-width class="text-orange pt-5 mr-10" />
                    <div>
                        <p class="font-semibold">Date</p>
                        <p>{{ formatDate(state.bookingInstance.course_instance.start_date) }}</p>
                        <p>{{ formatTime(state.bookingInstance.course_instance.start_time) }}</p>
                        <p>{{ state.bookingInstance.duration }}</p>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-1 lg:grid-cols-2 overflow-hidden">
                <div class="flex p-15">
                    <font-awesome-icon :icon="['fal', 'file-check']" fixed-width class="text-orange pt-5 mr-10" />
                    <div>
                        <p class="font-semibold">Terms</p>
                        <div v-if="!state.bookingInstance.terms_agreed">
                            <template v-if="dateHasPassed()">
                                You didn't agree terms and conditions for this booking.
                            </template>
                            <template v-else>
                                <p class="mb-15">Terms and conditions must be agreed to confirm your booking.<br><a href="/booking-terms" class="text-orange">Read terms and conditions.</a></p>
                                <button class="rounded p-10 bg-blue text-white font-semibold text-xs" @click.prevent="agreeTerms">Click to agree terms and conditions</button>
                            </template>
                        </div>
                        <p v-else="state.bookingInstance.terms_agreed">
                            Terms and conditions have been agreed for this booking.
                        </p>
                    </div>
                </div>
                <div class="flex p-15">
                    <font-awesome-icon :icon="['fal', 'download']" fixed-width class="text-orange pt-5 mr-10" />
                    <div>
                        <p class="font-semibold">Export Attendees</p>
                        <p class="text-xs"><a :href="'/download-course-booking-details/'+state.bookingInstance.id" class="text-orange">Download</a> a copy of this booking, including attendee information as a CSV file.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import { reactive, computed, onMounted } from 'vue'
import moment from 'moment';
import { foreach } from 'lodash';

const props = defineProps(['booking'])

const state = reactive({
    bookingInstance: props.booking,
    loading: false
})

const statusClasses = computed(() => {
    return {
        'px-10 py-5 text-xs text-white font-semibold rounded' : true,
        'bg-blue-500' : state.bookingInstance.status == "awaiting",
        'bg-orange-500' : state.bookingInstance.status == "confirmed",
        'bg-green-500' : state.bookingInstance.status == "completed",
        'bg-red-500' : state.bookingInstance.status == "cancelled",
    }
})

const toggleIcon = computed(() => {
    return state.bookingInstance.open ? 'angle-up' : 'angle-down'
})

function formatDate(date) {
    return moment(date).format('Do MMM Y');
}

function formatTime(time) {
    return moment(time).format('h:mm a');
}

function agreeTerms() {
    state.loading = true;

    axios({
        method: 'post',
        url: window.location,
        data: { booking_id: props.booking.id },
        headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onAgreeTerms' }
    }).then(response => {
        state.bookingInstance.terms_agreed = response.data.confirmed;
    }).catch(response => {
    }).then(() => {
        state.loading = false;
    });
}

function dateHasPassed() {
    return moment(props.booking.course_instance.end_date).isBefore(moment());
}

/**
 *
 * Gets the value of a URL query parameter for the given variable
 * http://ess.com?redirect=what_ever_is_here
 * getQueryVariable(redirect); would return what_ever_is_here
 */
function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0; i<vars.length;i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
}

function getCertificateDownloadUrl(bookingId, attendee) {
    let hasCert = false;
    let certId = false;
    _.forEach(attendee.certificates, (cert) => {
        if (hasCert) {
            // stop looping!
            return false;
        } else {
            // check to see if certificate is form this booking
            if (cert.certificate_file) {
                hasCert = bookingId == cert.booking_id ? cert.id : false;
            }
        }
    });
    return hasCert;
}

onMounted(() => {
    if (getQueryVariable("confirm_terms") == props.booking.id) {
        props.booking.open = true;
        document.getElementById('booking_instance_id_'+props.booking.id).scrollIntoView();
    }
})

</script>