<template>
    <Checkbox v-for="option in options" :key="option"
        :id="makeId(option.label)"
        :checked="value.includes(option.value)"
        :name="name"
        :checkbox-value="option.value"
        @update:checked="(checkedState) => check(option.value, checkedState)"
        :theme="props.theme"
        >
        {{ option.label }}
    </Checkbox>
</template>

<script setup>

import { computed } from 'vue'

const emit = defineEmits(['update:model-value'])

const props = defineProps({
    modelValue: {
        type: Array,
        default: []
    },
    options: {
        type: Array,
        default: []
    },
    name: {
        type: String,
        default: ""
    },
    theme: {
        type: String,
        default: "simple"
    }
})

let value = computed(() => {
    return props.modelValue
})

/**
 * Determines whether to add or remove a value from the checked values
 *
 * @param { * } value
 * @param { Boolean } checked
 */
function check(value, checked) {

    let updatedValue = [...props.modelValue]

    if (checked) {
        // add to updatedValue
        updatedValue.push(value)
    } else {
        // remove from updatedValue
        updatedValue.splice(updatedValue.indexOf(value), 1)
    }

    emit("update:model-value", updatedValue)
}

/**
 * Creates an ID for the input
 *
 * @param { String } label
 */
function makeId(label) {
    let id = props.name + ' ' + label + ' input'
    return id.replace(/([a-z])([A-Z])/g, "$1-$2").replace(/[\s_]+/g, '-').toLowerCase()
}
</script>