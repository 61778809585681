<template>
    <div class="relative flex flex-col sm:flex-row sm:gap-10 bg-orange-100 lg:bg-gray-50 lg:hover:bg-orange-100 p-20 mb-10 rounded-lg">

        <div class="text-xs font-semibold uppercase mb-10 sm:mb-0 sm:bg-black sm:text-white sm:flex sm:flex-col sm:items-center sm:py-[8px] sm:px-15 sm:rounded self-start">
            <span class="mr-5 sm:mr-0">{{ formatDate(courseDate.start_date, 'D') }}</span>
            <span>{{ formatDate(courseDate.start_date, 'MMM') }}</span>
        </div>

        <div class="flex flex-col sm:flex-1 sm:truncate">
            <p v-if="!courseDate.is_national && courseDate.distance" class="text-xs leading-none">{{ distance }}</p>
            <div class="font-bold" :title="courseDate.is_national ? 'Online Training' : courseDate.venue_name">{{ courseDate.is_national ? "Online Training" : courseDate.venue_name }}</div>

            <div class="text-xs font-normal mb-10 sm:mb-0" :title="courseDate.delivery">{{ courseDate.delivery }}</div>

            <div v-if="courseDate.dates_string || courseDate.times_string" class="mt-10 mb-10 sm:mb-0">
                <p v-if="courseDate.dates_string" class="text-sm"><font-awesome-icon :icon="['fas', 'calendar']" fixed-width class="mr-10 text-orange"></font-awesome-icon>{{ courseDate.dates_string }}</p>
                <p v-if="courseDate.times_string" class="text-sm"><font-awesome-icon :icon="['fas', 'clock']" fixed-width class="mr-10 text-orange"></font-awesome-icon>{{ courseDate.times_string }}</p>
            </div>

        </div>

        <div class="flex items-center justify-between">
            <div class="text-2xl font-semibold sm:w-[140px] sm:text-right sm:mr-10">
                <div v-if="courseDate.is_discounted" class="font-light text-xs uppercase leading-none">was &pound;{{ courseDate.base_price_per_attendee }}</div>
                <div class="leading-none">&pound;{{ courseDate.price_per_attendee }}</div>
            </div>
            <div>
                <a rel="nofollow" :href="'/book/course/'+courseDate.id" class="flex leading-none rounded bg-orange hover:bg-black text-white text-sm text-center px-20 py-[12px]">
                    Book Now
                </a>
                <div class="absolute top-20 right-20 sm:static sm:mt-5 text-center bg-blue-500 text-white p-5 rounded font-light text-xs leading-none">{{ courseDate.open_spaces }} spaces left</div>
            </div>
        </div>
        </div>
</template>

<script setup>

import moment from 'moment'

import { computed } from 'vue'

const distance = computed(() => {
    return Math.round(props.courseDate.distance) + ' miles';
})

const props = defineProps({
    'course-date': {
        type: Object
    }
})

function formatDate(date, format) {
    return moment(date).format(format);
}
</script>
