<template>
    <div id="account-password">
        <AccountHeading>Change Password</AccountHeading>

        <p class="mb-10 md:mb-20 xl:mb-30">Fill out the fields below to change your password.</p>

        <p v-if="passwordUpdateFailed" @click="passwordUpdateFailed=false"
            class="mb-10 bg-red-100 text-red-500 px-15 py-10 rounded hover:bg-red-200 cursor-pointer flex justify-between items-center">
            <span>{{ passwordUpdateFailed }}</span><font-awesome-icon :icon="['fal', 'times']" fixed-width></font-awesome-icon>
        </p>

        <p v-if="success" @click="success=false"
            class="mb-10 bg-green-200 text-green-700 px-15 py-10 rounded hover:bg-green-300 cursor-pointer flex justify-between items-center">
            <span>{{ success }}</span><font-awesome-icon :icon="['fal', 'times']" fixed-width></font-awesome-icon>
        </p>

        <form class="flex flex-col gap-15 md:gap-20 lg:gap-30 max-w-[600px] rounded-lg border border-gray-200 bg-gray-50 p-30" @submit.prevent="updatePassword" >

            <div class="flex flex-col">
                <FormLabel for="current-password-input">Current Password</FormLabel>
                <PasswordInput v-model="password_current" id="current-password-input" type="password" name="password_current" placeholder="Enter your current password" />
                <ValidationError v-if="errors.password_current">{{ errors.password_current[0] }}</ValidationError>
            </div>
            <div class="flex flex-col">
                <FormLabel for="password-input">New Password</FormLabel>
                <PasswordInput v-model="password" id="password-input" type="password" name="password" placeholder="Enter a new password" />
                <ValidationError v-if="errors.password">{{ errors.password[0] }}</ValidationError>
            </div>

            <SubmitButton type="submit" class="self-start">Change Password</SubmitButton>

        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                updating: false,
                password_current: '',
                password: '',
                errors: {},
                success: false,
                passwordUpdateFailed: false
            }
        },
        methods: {
            updatePassword() {
                this.updating = true
                this.errors = {}
                axios.post(window.location.href, {
                    password_current: this.password_current,
                    password: this.password,
                }, {
                    headers: { 'X-OCTOBER-REQUEST-HANDLER' : 'onUpdatePassword' }
                }).then(response => {
                    this.success = response.data.message
                    this.password = ''
                    this.password_current = ''
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.passwordUpdateFailed = "Something went wrong. Try again."
                        throw error;
                    }
                }).then(() => {
                    this.updating = false
                });
            }
        }
    }
</script>