<template>
    <div class="lg:block" :class="{ 'hidden' : !props.showFilters, '' : props.showFilters  }">

        <div class="mb-20">
            <p class="uppercase font-bold text-sm mb-10">Find dates for</p>
            <div class="flex gap-10">
                <label v-if="props.showOnline" for="show-online-input" class="p-10 rounded cursor-pointer" :class="{ 'text-white bg-gray-800' : state.filters.type=='online',  'bg-gray-50' : state.filters.type!='online'  }">
                    <input v-model="state.filters.type" id="show-online-input" type="radio" name="type" value="online" class="hidden" />Online training
                </label>

                <label v-if="props.showInPerson" for="show-in-person-input" class="p-10 rounded cursor-pointer" :class="{ 'text-white bg-gray-800' : state.filters.type=='in-person',  'bg-gray-50' : state.filters.type!='in-person'  }">
                    <input v-model="state.filters.type" id="show-in-person-input" type="radio" name="type" value="in-person" class="hidden" />In-person training
                </label>

            </div>
        </div>

        <div class="mb-20 p-15 bg-gray-50 rounded">
            <p class="uppercase font-bold text-sm mb-10">Start date</p>

            <div class="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-10">
                <div class="flex-1 flex flex-col">
                    <label for="dp-input-date-after-input" class="text-sm">After</label>
                    <DatePicker id="date-after-input" v-model="state.filters.date_from"></DatePicker>
                </div>
                <div class="flex-1 flex flex-col relative">
                    <label for="dp-input-date-before-input" class="text-sm">Before</label>
                    <DatePicker id="date-before-input" v-model="state.filters.date_to"></DatePicker>
                </div>
            </div>
        </div>

        <div v-show="courseType!='online'" class="mb-20 p-15 bg-gray-50 rounded">
            <p class="uppercase font-bold text-sm mb-10">Location</p>
            <div class="flex flex-col sm:flex-row lg:flex-col xl:flex-row gap-10">
                <div class="sm:w-1/2 lg:w-full xl:w-1/2 flex flex-col">
                    <label for="google-autocomplete-input" class="text-sm">Venues near</label>
                    <GoogleAutoComplete
                        id="google-autocomplete-input"
                        @place-updated="updateLocation"
                        @no-results-found="clearLocation">
                    </GoogleAutoComplete>
                </div>
                <div class="sm:w-1/2 lg:w-full xl:w-1/2 flex flex-col">
                    <label for="course-dates-range-filter" class="text-sm">within</label>
                    <select v-model="state.filters.radius" id="course-dates-range-filter" class="border pt-[12px] pb-[11px] px-10 rounded">
                        <option value="0">All locations</option>
                        <option value="10">10 miles</option>
                        <option value="50">50 miles</option>
                        <option value="200">200 miles</option>
                    </select>
                </div>
            </div>
            <p v-if="validationErrors['location.longitude']" class="text-xs py-5 text-red-500">
                {{ validationErrors['location.longitude'][0] }}
            </p>
        </div>
    </div>
</template>

<script setup>

import { reactive, onMounted, watch, computed } from 'vue';

const emit = defineEmits(['filters-updated'])

const state = reactive({
    filters: {
        date_from: '',
        date_to: '',
        location: {
            name: "",
            latitude: null,
            longitude: null,
        },
        radius: 50,
        type: 'online'
    }
})

watch(
    () => state.filters,
    (filters) =>
    {
        emit('filters-updated', filters)
    },
    { deep: true }
)

const props = defineProps({
    initFilters: {
        type: Object
    },
    validationErrors: {
        type: Object
    },
    courseType: {
        type: String,
        default: 'online'
    },
    showFilters: {
        type: Boolean,
        default: false
    },
    showOnline: {
        type: Boolean,
        default: false
    },
    showInPerson: {
        type: Boolean,
        default: false
    }
})

onMounted(() => {
    state.filters = props.initFilters
})

function clearLocation() {
    state.filters.location = {
        name: "",
        latitude: null,
        longitude: null
    }
}

function updateLocation(place) {
    state.filters.location = {
        name: place.name,
        latitude: place.latitude,
        longitude: place.longitude
    }
}

</script>