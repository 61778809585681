<template>
    <div id="search-filters" class="flex flex-col gap-10">
        <div class="flex gap-10">
            <TextInput v-model="searchTerm" type="text" class="w-full" placeholder="Search by title" />
            <button @click.prevent="open=!open" class="bg-black text-white font-semibold py-10 px-20 rounded md:hidden">
                <font-awesome-icon :icon="['fal', 'sliders']"></font-awesome-icon>
            </button>
        </div>
        <div class="flex flex-col gap-10" :class="{ 'hidden md:flex' : !open }">
            <RadioInput id="all-option" v-model="courseType" name="courseType" value="all" theme="light">Courses &amp; NVQs</RadioInput>
            <RadioInput id="public-option" v-model="courseType" name="courseType" value="public" theme="light">Courses Only</RadioInput>
            <RadioInput id="online-option" v-model="courseType" name="courseType" value="online" theme="light">Bookable Online</RadioInput>
            <RadioInput id="nvqs-option" v-model="courseType" name="courseType" value="nvqs" theme="light">NVQs</RadioInput>
            <SubmitButton class="hidden md:block w-full">Search <font-awesome-icon :icon="['fal', 'search']" /></SubmitButton>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import debounce from 'lodash.debounce'

const props = defineProps({
    initTerm: String,
    initCourseType: String,
});

const emit = defineEmits(['filters-changed']);

const searchId = ref(generateUniqueSearchId())

const searchTerm = ref(props.initTerm);

const courseType = ref(props.initCourseType);

const open = ref(false)

const debouncedFilterChange = debounce(() => {
    emit('filters-changed', { term: searchTerm.value, courseType: courseType.value, searchId: searchId.value });
}, 500)

const debouncedLog = debounce(() => {
    if (searchTerm.value.length >= 3) {
        log()
    }
}, 1000)

watch(searchTerm, debouncedLog);
watch(searchTerm, checkSearchId)
watch(searchTerm, debouncedFilterChange);
watch(courseType, debouncedFilterChange);

function checkSearchId(newTerm, oldTerm) {
    let newWordCount = newTerm.trim().split(" ").length
    let oldWordCount = oldTerm.trim().split(" ").length

    if (newTerm.length <= 0) {
        searchId.value = generateUniqueSearchId()
    }

    if (newWordCount < oldWordCount) {
        searchId.value = generateUniqueSearchId()
    }
}

function generateUniqueSearchId() {
    axios.post(window.location.origin, {}, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-OCTOBER-REQUEST-HANDLER': 'onGetNewSearchId',
        }
    }).then(response => {
        searchId.value = response.data.searchId
    });
}

function log() {
    axios.post(window.location.origin, { term: searchTerm.value, searchId: searchId.value }, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-OCTOBER-REQUEST-HANDLER': 'onLogSearch',
        }
    });
}
</script>