<template>
    <input
        :id="props.id"
        ref="autocompleteInput"
        type="text"
        v-model="state.query"
        @blur="onBlur()"
        class="border p-10 rounded w-auto">
    <p v-if="state.noResults" class="text-red-500 text-xs">{{ state.noResults }}</p>
</template>

<script setup>

import { ref, reactive, onMounted } from 'vue'

const emit = defineEmits(['place-updated', 'no-results-found'])

const autocompleteInput = ref(null)

const state = reactive({
    query: '',
    noResults: false
})

const options = {
    types: ['geocode'],
    componentRestrictions: {
        country: ['uk']
    }
}

const props = defineProps(['id'])

let autocomplete = null;

onMounted(() => {
    window.addEventListener('google-maps-api-loaded', initAutocomplete);
})

function initAutocomplete() {
    autocomplete = new google.maps.places.Autocomplete(autocompleteInput.value, options);
    autocomplete.addListener('place_changed', autoCompletePlaceChanged)
}

function onBlur() {
    let selected = document.querySelector(".pac-container .pac-item-selected");
    let hovered = document.querySelector(".pac-container .pac-item:hover");
    let firstResult = document.querySelector(".pac-container .pac-item");
    /**
     * If no autocompleting has happened, the following should resolve to true,
     * which means we can use the query string to Geocode a location and use
     * that instead.
     */
    if (!selected && !hovered && firstResult) {
        state.query = firstResult.querySelector(".pac-item-query").innerText;

        let geocoder = new google.maps.Geocoder()

        geocoder.geocode({
            address: state.query,
            region: 'uk'
        }, function(results, status) {

            if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
                noResults()
            }

            if (status === google.maps.GeocoderStatus.OK) {
                let place = results[0]
                placeChanged(place)
            }
        })
    }
}

function autoCompletePlaceChanged() {
    let place = autocomplete.getPlace()

    if (!place.geometry) {
        noResults()
        return;
    }
    placeChanged(place)
}

function placeChanged(place) {
    state.noResults = false
    state.query = place.formatted_address
    emit('place-updated', formatResult(place))
}

function noResults() {
    emit('no-results-found')
    state.noResults = "No locations found for '" + state.query + "'"
}

function formatResult(place) {
    return {
        name: place.formatted_address,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng()
    }
}

</script>