<template>
    <div class="lg:flex-1 flex justify-stretch w-full max-w-[500px] 2xl:max-w-[700px]">
        <form action="/search" method="GET" class="mx-30 w-full bg-gray-900 p-20 lg:p-30 xl:p-40 2xl:p-50 rounded-xl">
            <input v-model="state.searchId" type="hidden" name="search_id">
            <p class="text-white lg:text-lg uppercase font-semibold mb-10 lg:mb-30"><font-awesome-icon :icon="['fal', 'search']" class="text-orange mr-15"></font-awesome-icon>Find a course</p>

            <div class="flex flex-col mb-10 lg:mb-20">
                <label for="search-term-input" class="text-xs text-gray-500 mb-5 lg:mb-10">Search by course title</label>
                <TextInput v-model="state.term" id="search-term-input" name="term"></TextInput>
            </div>

            <div class="grid grid-cols-2 gap-10 2xl:gap-30 mb-30">
                <CheckboxGroup v-model="state.types" name="types[]" :options="typeOptions" theme="dark" />
            </div>

            <button type="submit" class="w-full text-center justify-center bg-orange-500 p-10 md:p-15 2xl:p-20 flex items-center font-semibold text-white text-sm lg:text-base xl:text-lg rounded" >Find a course</button>
        </form>
    </div>
</template>

<script setup>

    import { ref, reactive } from 'vue';

    const state = reactive({
        term: '',
        types: [],
        searchId: generateUniqueSearchId(),
    })

    function generateUniqueSearchId() {
        axios.post(window.location.origin, {}, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-OCTOBER-REQUEST-HANDLER': 'onGetNewSearchId',
            }
        }).then(response => {
            state.searchId = response.data.searchId
        });
    }

    const typeOptions = ref([
        { label: "Courses", value: "courses" },
        { label: "NVQs", value: "nvqs" },
    ])

</script>