<template>
    <button @click="openModal" class="inline-block rounded bg-gray-900 hover:bg-black text-white xl:text-lg py-5 xl:py-10 px-15">
        <font-awesome-icon :icon="['fal', 'search']" fixed-width class="mr-10 text-orange"></font-awesome-icon>Search
    </button>
    <Teleport to="body">
        <Modal :open="open" @close="closeModal" container-classes="w-full max-w-[800px] h-5/6 p-30 p-30 mx-30 bg-black rounded-md shadow-lg transition-all duration-300">
            <div class="flex flex-col h-full justify-center items-center">
                <form action="/search" method="GET" class="w-full">
                    <div class="flex w-full">
                        <input v-model="searchId" ref="searchinput" type="hidden" name="search_id">
                        <input v-model="searchTerm" ref="searchinput" type="text" name="term" placeholder="Search courses and nvqs" class="text-white font-semibold flex-1 text-lg py-20 border-b bg-black border-white outline-none" autocomplete="off">
                        <button type="submit" class="p-20 -mr-20 outline-none flex-shrink text-white text-xl border-b border-white hover:text-orange">
                            <font-awesome-icon :icon="['fal', 'search']" />
                        </button>
                    </div>
                </form>
                <div v-if="searching" class="h-full flex-1 overflow-y-auto mt-30 text-white">
                    <font-awesome-icon :icon="['fal', 'spinner-third']" spin class="mr-10"></font-awesome-icon>Searching courses
                </div>
                <div v-if="!searching && courses.length <= 0" class="w-full h-full flex-1 overflow-y-auto mt-30 text-white">
                    <p class="bg-orange/40 px-10 py-30 rounded text-center">
                        {{ searchTerm.length >= 3 ? 'No courses found' : 'Enter a search term to find courses' }}
                    </p>
                </div>
                <div v-else class="w-full h-full flex-1 overflow-y-auto mt-30">
                    <a v-if="category" class="flex mb-10 p-15 text-lg bg-orange rounded text-black hover:bg-orange-400 transition-all font-semibold" :href="category.slug">{{ category.title }}</a>
                    <a v-for="course in courses" class="flex mb-10 p-15 text-lg bg-gray-900 rounded text-white hover:bg-orange transition-all hover:text-black" :href="course.slug">{{ course.title }}</a>
                </div>
                <form action="/search" method="GET">
                    <input name="term" type="hidden" v-model="searchTerm">
                    <input name="search_id" type="hidden" v-model="searchId">
                    <button type="submit" class="text-orange p-10 rounded hover:text-orange-400">More search options</button>2
                </form>
            </div>
        </Modal>
    </Teleport>
</template>

<script setup>

import { ref, watch, nextTick } from 'vue';
import debounce from 'lodash.debounce'

const open = ref(false)

const searching = ref(false)

const searchId = ref(generateUniqueSearchId())

const searchinput = ref(null)

const searchTerm = ref("")

const courses = ref([]);

const category = ref(false);

const debouncedSearch = debounce(() => {
    if (searchTerm.value.length >= 3) {
        search()
    } else {
        searching.value = false
    }
}, 1000)

const debouncedLogSearchTerm = debounce(() => {
    if (searchTerm.value.length >= 3) {
        log()
    }
}, 700)

watch(searchTerm, (newTerm, oldTerm) => {
    courses.value = [];

    if (newTerm.length >= 3) {
        searching.value = true
    }
})
watch(searchTerm, checkSearchId)
watch(searchTerm, debouncedSearch)
watch(searchTerm, debouncedLogSearchTerm)

function checkSearchId(newTerm, oldTerm) {
    let newWordCount = newTerm.trim().split(" ").length
    let oldWordCount = oldTerm.trim().split(" ").length

    if (newTerm.length <= 0) {
        searchId.value = generateUniqueSearchId()
    }

    if (newWordCount < oldWordCount) {
        searchId.value = generateUniqueSearchId()
    }
}

function generateUniqueSearchId() {
    axios.post(window.location.origin, {}, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-OCTOBER-REQUEST-HANDLER': 'onGetNewSearchId',
        }
    }).then(response => {
        searchId.value = response.data.searchId
    });
}

function log() {
    axios.post(window.location.origin, { term: searchTerm.value, searchId: searchId.value }, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-OCTOBER-REQUEST-HANDLER': 'onLogSearch',
        }
    });
}

function search()
{
    searching.value = true;

    courses.value = []

    category.value = false

    axios.post(window.location.origin, { term: searchTerm.value }, {
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-OCTOBER-REQUEST-HANDLER': 'onCourseSearch',
        }
    }).then(response => {
        courses.value = response.data.results
        category.value = response.data.category
    }).catch(error => {
    }).then(() => {
        searching.value = false;
    });
}

function closeModal() {
    searchTerm.value = ""
    courses.value = []
    category.value = false
    open.value = false
    document.getElementsByTagName('body')[0].classList.remove('fixed')
}

async function openModal() {
    open.value = true
    document.getElementsByTagName('body')[0].classList.add('fixed')

    // wait for dom to finish updating
    await nextTick()

    // now we can focus on the input element
    searchinput.value.focus();
}

</script>