<template>
    <form ref="registerForm">
        <div class="flex flex-col mb-20">
            <FormLabel>Full name</FormLabel>
            <TextInput name="name"/>
            <ValidationError v-if="errors.name" class="text-sm text-red-500 my-5 cursor-pointer" @click="errors.name=false">{{ errors.name[0] }}</ValidationError>
        </div>
        <div class="flex flex-col mb-20">
            <FormLabel>Email address</FormLabel>
            <TextInput type="email" name="email"/>
            <ValidationError v-if="errors.email" @click="errors.email=false">{{ errors.email[0] }}</ValidationError>
        </div>
        <div class="flex flex-col mb-20">
            <FormLabel>Password</FormLabel>
            <PasswordInput name="password" />
            <ValidationError v-if="errors.password" class="text-sm text-red-500 my-5 cursor-pointer" @click="errors.password=false">{{ errors.password[0] }}</ValidationError>
        </div>

        <div class="">
            <Checkbox v-model="accepts_marketing" id="accepts_marketing_input" name="accepts_marketing">Receive marketing communications</Checkbox>
        </div>
    </form>
</template>

<script>
    export default {
        data() {
            return {
                errors: {},
                registrationFailed: false,
                accepts_marketing: false
            }
        },

        props: [
            'checkoutOrder'
        ],

        mounted() {
            this.emitter.on('register', () => {
                this.register()
            })
        },

        methods: {
            register() {
                this.errors = {}
                this.registeringFailed = false

                // append some data to a form object
                let formData = new FormData(this.$refs.registerForm)
                formData.append('username', formData.get('email'))
                formData.append('redirectUrl', window.location.href)
                formData.append('orderId', this.checkoutOrder.id)

                // axios request
                axios.post(window.location.href, formData, {
                    'headers' : {
                        'X-OCTOBER-REQUEST-HANDLER': 'onCheckoutRegister',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    window.location = response.data.redirectUrl
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.registrationFailed = error.response.data
                        throw error;
                    }
                }).then(() => {
                    this.emitter.emit('finished-register-login')
                })
            }
        }
    }
</script>
