<template>
    <div v-if="generalError" class="bg-red-600 p-20 rounded text-white mb-20">
        {{ generalError }}
    </div>

    <div v-if="showSuccess" class="bg-green-600 p-20 rounded text-white mb-20">
        <p>Thank you, your message has been successfully sent. A member from our team will be in touch shortly.</p>
    </div>

    <form v-else v-on:submit.prevent="submitForm" id="contact_form" class="p-30 bg-gray-50 border border-gray-200 rounded-lg mb-30">
        <div class="mb-20 flex flex-col sm:flex-row lg:flex-col 2xl:flex-row gap-20 xl:gap-30">
            <div class="flex-1 flex flex-col">
                <FormLabel for="first-name-input">First Name <span class="req">*</span></FormLabel>
                <TextInput type="text" name="first_name" id="first-name-input" />
                <ValidationError v-if="errors.first_name" class="help-text error">{{ errors.first_name[0] }}</ValidationError>
            </div>
            <div class="flex-1 flex flex-col">
                <FormLabel for="last-name-input">Last Name <span class="req">*</span></FormLabel>
                <TextInput type="text" name="last_name" id="last-name-input" />
                <ValidationError v-if="errors.last_name" class="help-text error">{{ errors.last_name[0] }}</ValidationError>
            </div>
        </div>
        <div class="flex-1 flex flex-col mb-20">
            <FormLabel for="company-input">Company name</FormLabel>
            <TextInput name="company" id="company-input" />
            <ValidationError v-if="errors.company" class="help-text error">{{ errors.company[0] }}</ValidationError>
        </div>
        <div class="mb-20 flex flex-col sm:flex-row lg:flex-col 2xl:flex-row gap-20 xl:gap-30">
            <div class="flex-1 flex flex-col">
                <FormLabel for="phone-input">Phone number <span class="req">*</span></FormLabel>
                <TextInput name="phone" id="phone-input" />
                <ValidationError v-if="errors.phone" class="help-text error">{{ errors.phone[0] }}</ValidationError>
            </div>
            <div class="flex-1 flex flex-col">
                <FormLabel for="email-input">Email address <span class="req">*</span></FormLabel>
                <TextInput name="email" id="email-input" />
                <ValidationError v-if="errors.email" class="help-text error">{{ errors.email[0] }}</ValidationError>
            </div>
        </div>
        <div class="flex flex-col mb-20 ">
            <FormLabel for="message">Message <span class="req">*</span></FormLabel>
            <TextareaInput rows="5" name="message" id="message"></TextareaInput>
            <ValidationError v-if="errors.message" class="help-text error">{{ errors.message[0] }}</ValidationError>
        </div>
        <div class="cf-turnstile" data-sitekey="0x4AAAAAAAdaw_8EQjVm8-J2"></div>
        <div class="flex gap-10 flex-col md:flex-row lg:flex-col xl:flex-row md:justify-between lg:justify-normal xl:justify-between">
            <Checkbox v-model="receivesMarketing" id="accepts_marketing_input" name="accepts_marketing">Receive marketing communications</Checkbox>
            <SubmitButton>Send message <font-awesome-icon :icon="buttonIcon" v-bind:spin="loading"/></SubmitButton>
        </div>
    </form>
</template>
<script>
    export default {
        data: function() {
            return {
                errors: false,
                generalError: false,
                showSuccess: false,
                loading: false,
                receivesMarketing: true
            }
        },
        computed: {
            buttonIcon: function() {
                if (this.loading) {
                    return ['fal', 'spinner-third'];
                }

                if (this.showSuccess) {
                    return ['fal', 'check'];
                }

                return ['fal', 'arrow-right'];
            }
        },
        methods: {
            submitForm: function() {
                this.loading = true;
                this.generalError = false;

                let formData = new FormData(document.getElementById('contact_form'));

                axios({
                    method: 'post',
                    url: '/contact-us',
                    data: formData,
                    headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onSubmitContactForm', 'X-Requested-With': 'XMLHttpRequest' }
                }).then(response => {
                    this.showSuccess = true;
                }).catch(e => {
                    if (e.response?.status == 406) {
                        this.errors = e.response.data.X_OCTOBER_ERROR_FIELDS;
                    }
                    else {
                        this.generalError = e.response.data;
                        throw e;
                    }
                }).then(() => {
                    this.loading = false;
                });
            }
        }
    }
</script>0