<template>
    <div id="edit_profile">
        <AccountHeading>Edit Profile</AccountHeading>

        <form id="edit_profile_form" @submit.prevent="submitForm" class="rounded-lg border border-gray-200 bg-gray-50 p-30 mb-50">

            <div class="grid gap-15 md:gap-20 lg:gap-30 grid-cols-1 md:grid-cols-2 mb-15 md:mb-20 lg:mb-30">

                <div class="flex flex-col">
                    <FormLabel for="name">First name <span class="req">*</span></FormLabel>
                    <TextInput name="name" type="text" class="form-control" id="name" v-model="state.accountModel.name" required />
                    <ValidationError v-if="state.errors.name">{{ state.errors.name[0] }}</ValidationError>
                </div>

                <div class="flex flex-col">
                    <FormLabel for="surname">Last name <span class="req">*</span></FormLabel>
                    <TextInput name="surname" type="text" class="form-control" id="surname" v-model="state.accountModel.surname" required />
                    <ValidationError v-if="state.errors.surname">{{ state.errors.surname[0] }}</ValidationError>
                </div>

                <div class="flex flex-col">
                    <FormLabel for="email">Email address <span class="req">*</span></FormLabel>
                    <TextInput name="email" type="email" class="form-control" id="email" v-model="state.accountModel.email" required/>
                    <ValidationError v-if="state.errors.email">{{ state.errors.email[0] }}</ValidationError>
                </div>

                <div class="flex flex-col">
                    <FormLabel for="phone">Phone number <span class="req">*</span></FormLabel>
                    <TextInput name="phone" type="text" class="form-control" id="phone" v-model="state.accountModel.phone" required />
                    <ValidationError v-if="state.errors.phone">{{ state.errors.phone[0] }}</ValidationError>
                </div>

                <div class="flex flex-col">
                    <FormLabel for="company">Company</FormLabel>
                    <TextInput name="company" type="text" class="form-control" id="company" v-model="state.accountModel.company" />
                    <ValidationError v-if="state.errors.company">{{ state.errors.company[0] }}</ValidationError>
                </div>

                <div class="flex flex-col">
                    <FormLabel for="citb_levy_number">CITB Levy Number</FormLabel>
                    <TextInput name="citb_levy_number" type="text" class="form-control" id="citb_levy_number" v-model="state.accountModel.citb_levy_number" />
                    <ValidationError v-if="state.errors.citb_levy_number">{{ state.errors.citb_levy_number[0] }}</ValidationError>
                </div>
            </div>

            <SubmitButton type="submit">Save <font-awesome-icon :icon="buttonIcon" :spin="state.loading" /></SubmitButton>

        </form>
    </div>
</template>

<script setup>

import { computed, onMounted, reactive } from 'vue';

const state = reactive({
    generalError: false,
    errors: {},
    loading: false,
    success: false,
    accountModel: false
})

const props = defineProps(['accountData'])

const buttonIcon = computed(() => {
    if (state.loading) {
        return ['fal', 'spinner-third'];
    }

    return ['fal', 'angle-right'];
})

onMounted(() => {
    state.accountModel = {
        name: props.accountData.name,
        surname: props.accountData.surname,
        email: props.accountData.email,
        phone: props.accountData.profile.phone,
        company: props.accountData.profile.company,
        citb_levy_number: props.accountData.profile.citb_levy_number,
    };
})

function submitForm() {
    state.loading = true;

    let formData = new FormData(document.getElementById('edit_profile_form'));

    axios({
        method: 'post',
        url: window.location,
        data: formData,
        headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onUpdate', 'X-Requested-With': 'XMLHttpRequest' }
    }).then(response => {
        state.success = true;
    }).catch(e => {
        if (e.response?.status == 406) {
            state.errors = e.response.data.X_OCTOBER_ERROR_FIELDS;
        } else {
            state.generalError = e.message;
            throw e;
        }
    }).then(() => {
        state.loading = false;
    });
}
</script>