<template>
    <label :for="id" :class="labelClasses">
        <input :class="inputClasses"
            @input="event => inputChanged(event)"
            v-model="model"
            :value="value"
            type="radio"
            :name="name"
            :selected="selected"
            :id="id"
        >
        <slot />
    </label>
</template>

<script setup>
import { computed, reactive } from 'vue';

const props = defineProps({
    id: {
        type: String,
        default: ""
    },
    selected: {
        type: Boolean,
        default: false
    },
    modelValue: {
        type: [String, Boolean, Array],
        default: false
    },
    name: {
        type: String,
        default: ""
    },
    value: {
        type: String,
        default: ""
    },
    theme: {
        type: String,
        default: "simple"
    }
})

const state = reactive({
    selected: props.selected
})

const emit = defineEmits([ "update:model-value", "update:selected"])

const model = computed({
    get() {
        return props.modelValue
    },

    set(value) {
        emit("update:model-value", value)
    }
})

function inputChanged(event) {
    state.selected = event.target.selected
    emit('update:selected', event.target.selected)
}

const labelClasses = computed(() => {
    return {
        'flex items-center font-semibold cursor-pointer': true,
        'p-10 md:p-[8px] lg:p-[12px] 2xl:p-15 rounded border': props.theme == "light" || props.theme == "dark"
        ,
        'bg-black border-black hover:border-orange text-white hover:text-orange': props.theme == "dark" && !state.selected,
        'bg-black border-orange text-white': props.theme == "dark" && state.selected,

        'bg-orange-50 border-transparent hover:bg-orange-100': props.theme == "light" && !state.selected,
        'bg-orange-100 border-transparent': props.theme == "light" && state.selected
    }
})

const inputClasses = computed(() => {
    return {
        '': true,
        'mr-5 md:mr-[7px] lg:mr-10': props.theme == "simple",
        'w-20 lg:w-25 h-20 lg:h-25 mr-10 lg:mr-15': props.theme == "light" || props.theme == "dark",
        'text-white accent-orange-500': props.theme == "dark",
        'accent-gray-800': props.theme == "light"
    }
})

</script>