<template>
    <div class="w-full flex lg:flex-col items-center lg:items-start lg:justify-center mb-10 lg:mb-0 p-15 md:p-20 xl:p-30 rounded lg:rounded-xl shadow-lg bg-white border border-gray-50">
        <font-awesome-icon :icon="icon" fixed-width :class="props.iconColor" class="mr-15 text-xl lg:text-3xl lg:mb-10"></font-awesome-icon>
        <div class="">
            <p class="font-bold text-xs">{{ label }}</p>
            <p class="text-xs">{{ value }}</p>
        </div>
    </div>
</template>

<script setup>

const props = defineProps(['label', 'value', 'icon', 'icon-color'])

</script>