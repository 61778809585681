<template>
    <div class="search-course-types-picker">
        <div @click.prevent="optionsOpen = !optionsOpen" class="picker-toggle">
            <span>{{ selectedOption.label }}</span>
            <font-awesome-icon :icon="toggleIcon" />
        </div>
        <div v-show="optionsOpen" class="course-type-options">
            <div v-for="option in options" class="option">
                <div @click.prevent="setSelected(option)">{{ option.label }}</div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                optionsOpen: false,
                selectedOption: { value: 'all', label: 'Courses & NVQs' },
                options: {
                    option_1: { value: 'all', label: 'Courses & NVQs' },
                    option_2: { value: 'public', label: 'Courses Only' },
                    option_3: { value: 'online', label: 'Bookable Online' },
                    option_4: { value: 'nvqs', label: 'NVQs' },
                    option_4: { value: 'apprenticeships', label: 'Apprenticeships' },
                }
            }
        },
        computed: {
            toggleIcon() {
                return this.optionsOpen ? ['fal', 'angle-up'] : ['fal', 'angle-down'] ;
            }
        },
        methods: {
            setSelected(selected) {
                this.closeOptions();
                this.selectedOption = selected;
                this.$emit('type-set', selected);
            },
             closeOptions() {
                this.optionsOpen = false;
             }
        }
    }
</script>