/**
 * Brand Icons
 */
import {
    faFacebook as fabFacebook,
    faInstagram as fabInstagram,
    faLinkedin as fabLinkedin,
    faXTwitter as fabXTwitter
} from '@fortawesome/free-brands-svg-icons'

/**
 * Light Icons
*/
import {
    faAngleDown as falAngleDown,
    faAngleLeft as falAngleLeft,
    faAngleRight as falAngleRight,
    faAngleUp as falAngleUp,
    faArrowLeft as falArrowLeft,
    faArrowProgress as falArrowProgress,
    faArrowRight as falArrowRight,
    faArrowUpRightFromSquare as falArrowUpRightFromSquare,
    faBars as falBars,
    faBuilding as falBuilding,
    faCertificate as falCertificate,
    faClock as falClock,
    faCalendar as falCalendar,
    faCartPlus as falCartPlus,
    faCartShopping as falCartShopping,
    faCartXmark as falCartXmark,
    faCheck as falCheck,
    faCheckCircle as falCheckCircle,
    faChevronLeft as falChevronLeft,
    faChevronRight as falChevronRight,
    faDiploma as falDiploma,
    faDoorOpen as falDoorOpen,
    faDownload as falDownload,
    faEnvelope as falEnvelope,
    faExclamation as falExclamation,
    faFileAlt as falFileAlt,
    faFileCertificate as falFileCertificate,
    faFileCheck as falFileCheck,
    faGlobePointer as falGlobePointer,
    faIdCard as falIdCard,
    faInfo as falInfo,
    faInfoSquare as falInfoSquare,
    faList as falList,
    faListCheck as falListCheck,
    faLock as falLock,
    faMapMarker as falMapMarker,
    faMinus as falMinus,
    faMoneyBill as falMoneyBill,
    faPencil as falPencil,
    faPercent as falPercent,
    faPhone as falPhone,
    faPlus as falPlus,
    faSearch as falSearch,
    faSignIn as falSignIn,
    faSignOut as falSignOut,
    faSliders as falSliders,
    faSpinnerThird as falSpinnerThird,
    faSquarePhone as falSquarePhone,
    faStar as falStar,
    faSync as falSync,
    faTag as falTag,
    faThumbsUp as falThumbsUp,
    faTimes as falTimes,
    faTimer as falTimer,
    faTrash as falTrash,
    faUser as falUser,
    faUsers as falUsers,
    faUserPlus as falUserPlus,
} from '@fortawesome/pro-light-svg-icons'

import {
    faInfoSquare as fasInfoSquare,
    faMapMarker as fasMapMarker,
    faCalendar as fasCalendar,
    faClock as fasClock,
} from '@fortawesome/pro-solid-svg-icons'

export const icons = {
    fabFacebook,
    fabInstagram,
    fabLinkedin,
    fabXTwitter,

    falAngleDown,
    falAngleLeft,
    falAngleRight,
    falAngleUp,
    falArrowLeft,
    falArrowProgress,
    falArrowRight,
    falArrowUpRightFromSquare,
    falBars,
    falBuilding,
    falCertificate,
    falCheck,
    falClock,
    falCalendar,
    falCartPlus,
    falCartShopping,
    falCartXmark,
    falCheck,
    falCheckCircle,
    falChevronLeft,
    falChevronRight,
    falDiploma,
    falDoorOpen,
    falDownload,
    falEnvelope,
    falExclamation,
    falFileAlt,
    falFileCertificate,
    falFileCheck,
    falGlobePointer,
    falIdCard,
    falInfo,
    falInfoSquare,
    falList,
    falListCheck,
    falLock,
    falMapMarker,
    falMinus,
    falMoneyBill,
    falPencil,
    falPercent,
    falPhone,
    falPlus,
    falSearch,
    falSignIn,
    falSignOut,
    falSliders,
    falSpinnerThird,
    falSquarePhone,
    falStar,
    falSync,
    falTag,
    falThumbsUp,
    falTimes,
    falTimer,
    falTrash,
    falUser,
    falUsers,
    falUserPlus,

    fasInfoSquare,
    fasMapMarker,
    fasCalendar,
    fasClock,
}