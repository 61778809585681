<template>
    <div>
        <div v-if="showAddress">
            <div class="mb-10 whitespace-pre-line">{{ currentAddress.replaceAll(', ',',\n') }}</div>
            <div class="flex justify-between">
                <button @click.prevent="editing = true" class="bg-green-500 hover:bg-green-400 text-white rounded-sm px-10 py-[3px] -mx-5 -my-2">Edit</button>
                <slot name="success"></slot>
            </div>
        </div>

        <div v-else class="bg-gray-50 border border-gray-200 p-20 rounded">
            <button v-if="this.currentAddress" @click.prevent="setState" class= "mb-10 text-xs text-white font-semibold py-5 px-10 bg-black rounded">
                <font-awesome-icon :icon="['fal', 'chevron-left']" class="mr-5 text-xs"></font-awesome-icon>Back
            </button>

            <!-- Update Form -->
            <form v-if="editing && updatingCurrent" ref="updateAddressForm" @submit.prevent="updateAddress">
                <div class="mb-10">
                    <TextareaInput v-model="updatedAddress" rows="3"></TextAreaInput>
                    <ValidationError v-if="addressUpdateErrors.address" @click.prevent="addressUpdateErrors.address = false">{{ addressUpdateErrors.address[0] }}<font-awesome-icon :icon="['fal', 'times']" class="ml-5"></font-awesome-icon></ValidationError>
                </div>
                <SubmitButton>Save address</SubmitButton>
            </form>

            <!-- New address form -->
            <form v-if="editing && creatingNew" id="createAddressForm" ref="createAddressForm" @submit.prevent="createNewAddress">
                <p class="bg-blue text-white p-10 text-xs rounded mb-10">Please enter a valid address and press the 'Save address' button below before continuing.</p>
                <div class="flex flex-col mb-10">
                    <FormLabel for="address_1">Address line 1</FormLabel>
                    <TextInput id="address_1" name="address_1" class="w-full" />
                    <ValidationError v-if="newAddressErrors.address_1" @click.prevent="newAddressErrors.address_1 = false">Please enter a first line of address</ValidationError>
                </div>
                <div class="flex flex-col mb-10">
                    <FormLabel for="address_2">Address line 2</FormLabel>
                    <TextInput id="address_2" name="address_2" class="w-full" />
                    <ValidationError v-if="newAddressErrors.address_2" @click.prevent="newAddressErrors.address_2 = false">Please enter a second line of address</ValidationError>
                </div>
                <div class="flex flex-col mb-10">
                    <FormLabel for="city_town">City / Town</FormLabel>
                    <TextInput id="city_town" name="city_town" class="w-full" />
                    <ValidationError v-if="newAddressErrors.city_town" @click.prevent="newAddressErrors.city_town = false">Please enter a city or town</ValidationError>
                </div>
                <div class="flex flex-col mb-10">
                    <FormLabel for="county">County</FormLabel>
                    <TextInput id="county" name="county" class="w-full" />
                    <ValidationError v-if="newAddressErrors.county" @click.prevent="newAddressErrors.county = false">Please enter a county</ValidationError>
                </div>
                <div class="flex flex-col mb-10">
                    <FormLabel for="postcode">Postcode</FormLabel>
                    <TextInput id="postcode" name="postcode" class="w-full" />
                    <ValidationError v-if="newAddressErrors.postcode" @click.prevent="newAddressErrors.postcode = false">Please enter a postcode</ValidationError>
                </div>
                <SubmitButton>Save address</SubmitButton>
            </form>

            <!-- Address Picker -->
            <form v-if="editing && choosingExisting && (availableAddressesCount > 0)" ref="AddressPickerForm" @submit.prevent="chooseExistingAddress" class="mb-10">
                <select class="mb-15 text-sm rounded bg-white border border-gray-300 w-full py-[6px] px-[6px]" v-model="selectedAddress">
                    <option v-for="addressOption, key in availableAddresses" :key="key" :value="addressOption">{{ addressOption }}</option>
                </select>
                <SubmitButton >Use selected address</SubmitButton>
            </form>

            <div v-if="editing && ((currentAddress && !creatingNew) || (availableAddressesCount > 0))" class="mt-10">
                <div v-if="editing && ((currentAddress && !creatingNew) || (availableAddressesCount > 0))" class="text-sm font-bold mb-5">More options</div>

                <div class="flex flex-col gap-10 items-start">
                    <button v-if="editing && !choosingExisting && (availableAddressesCount > 0)"
                            @click.prevent="choosingExisting = true, creatingNew = false, clearErrors()"
                            class="flex items-center text-xs">
                            <span class="mr-5">Choose from my addresses</span><font-awesome-icon :icon="['fal', 'chevron-right']" class="text-xs ml-5"></font-awesome-icon>
                    </button>

                    <button
                        v-if="editing && !creatingNew"
                        @click.prevent="creatingNew = true, choosingExisting = false, clearErrors()"
                        class="flex items-center text-xs">
                        <span class="mr-5">Create a new address</span><font-awesome-icon :icon="['fal', 'chevron-right']" class="text-xs ml-5"></font-awesome-icon>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                editing: false,
                creatingNew: false,
                updatedAddress: '',
                selectedAddress: '',
                availableAddresses: {},
                choosingExisting: false,
                newAddressErrors: {},
                addressUpdateErrors: {},
            }
        },

        mounted() {
            this.setState()
            this.selectedAddress = this.currentAddress
            this.updatedAddress = this.currentAddress
            this.getAvailableAddresses()
            this.emitter.on('updated-available-addresses', (addresses) => {
                this.availableAddresses = addresses
            })
        },

        props: {
            'current-address': {
                type: String,
                default: '',
            },
        },

        watch: {
            currentAddress(newValue, oldValue) {
                if (!oldValue && newValue) {
                    this.editing = false,
                    this.creatingNew = false
                }

                this.updatedAddress = this.currentAddress
                this.selectedAddress = this.currentAddress
                this.getAvailableAddresses()
            }
        },

        computed: {
            showAddress() {
                return this.currentAddress && !this.editing
            },

            updatingCurrent() {
                return this.editing && !this.creatingNew && !this.choosingExisting
            },

            availableAddressesCount() {
                return Object.keys(this.availableAddresses).length
            }
        },

        methods: {
            setState() {
                this.clearErrors()

                if (!this.currentAddress) {
                    this.editing = true
                    this.creatingNew = true
                    return
                }

                if (this.updatingCurrent) {
                    this.updatedAddress = this.currentAddress
                    this.editing = false
                    this.creatingNew = false
                    this.choosingExisting = false
                    return
                }

                this.creatingNew = false
                this.choosingExisting = false
            },

            getAvailableAddresses() {
                axios.post(window.location.href, {}, {
                    'headers' : {
                        'X-OCTOBER-REQUEST-HANDLER' : 'onGetAvailableAddresses',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    this.emitter.emit('updated-available-addresses', response.data.addresses)
                })
            },

            updateAddress() {
                this.clearErrors()

                axios.post(window.location.href, { newAddress: this.updatedAddress }, {
                    'headers': {
                        'X-OCTOBER-REQUEST-HANDLER' : 'onValidateAddressUpdate',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    this.$emit('addressUpdated', this.updatedAddress)
                    this.editing = false
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.addressUpdateErrors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        throw error;
                    }
                }).then(() => {
                })
            },

            createNewAddress() {

                let formData = new FormData(this.$refs.createAddressForm)
                this.newAddressErrors = {}

                axios.post(window.location.href, formData, {
                    'headers' : {
                        'X-OCTOBER-REQUEST-HANDLER' : 'onCreateAddress',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    this.$emit('addressUpdated', response.data.address_string)
                    this.editing = false
                    this.creatingNew = false
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.newAddressErrors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        throw error;
                    }
                }).then(() => {
                });
            },

            chooseExistingAddress() {
                this.addressUpdateErrors = {}
                this.newAddressErrors = {}
                this.$emit('addressUpdated', this.selectedAddress)
                this.choosingExisting = false
                this.editing = false
            },

            clearErrors() {
                this.addressUpdateErrors = {}
                this.newAddressErrors = {}
            }
        }
    }
</script>