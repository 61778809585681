<template>
    <div id="registration">
        <AccountHeading>Register</AccountHeading>
        <p class="mb-20">By creating an account you are agreeing to the Essential Site Skills <a href="/terms-conditions" class="text-orange cursor-pointer hover:underline font-semibold" target="_blank">terms and conditions</a>.</p>

        <div v-if="generalError">
            {{ generalError }}
        </div>

        <form v-on:submit.prevent="submitRegistration" id="registration_form" method="post" class="mb-20 p-30 bg-gray-50 border border-gray-200 rounded-lg">
            <div class="mb-20 flex flex-col">
                <FormLabel for="registerFirstName">First Name <span class="req">*</span></FormLabel>
                <TextInput name="name" id="registerFirstName"/>
                <ValidationError v-if="errors.name" class="help-text error">{{ errors.name[0] }}</ValidationError>
            </div>

            <div class="mb-20 flex flex-col">
                <FormLabel for="registerLastName">Last Name <span class="req">*</span></FormLabel>
                <TextInput name="surname" id="registerLastName"/>
                <ValidationError v-if="errors.surname" class="help-text error">{{ errors.surname[0] }}</ValidationError>
            </div>
            <div class="mb-20 flex flex-col">
                <FormLabel for="registerEmail">Email address <span class="req">*</span></FormLabel>
                <TextInput name="email" id="registerEmail"/>
                <ValidationError v-if="errors.email" class="help-text error">{{ errors.email[0] }}</ValidationError>
            </div>
            <div class="mb-20 flex flex-col">
                <FormLabel for="registerPhone">Phone number <span class="req">*</span></FormLabel>
                <TextInput name="phone" id="registerPhone"/>
                <ValidationError v-if="errors.phone" class="help-text error">{{ errors.phone[0] }}</ValidationError>
            </div>
            <div class="mb-20 flex flex-col">
                <FormLabel for="registerPassword">Password <span class="req">*</span></FormLabel>
                <PasswordInput name="password" id="registerPassword"/>
                <ValidationError v-if="errors.password" class="help-text error">{{ errors.password[0] }}</ValidationError>
            </div>

            <div class="mb-20">
                <Checkbox v-model="accepts_marketing" id="accepts_marketing_input" name="accepts_marketing">Receive marketing communications</Checkbox>
            </div>

            <SubmitButton id="registration_submit">Create account <font-awesome-icon :icon="buttonIcon" v-bind:spin="loading"></font-awesome-icon></SubmitButton>
        </form>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {
                generalError: false,
                errors: false,
                loading: false,
                accepts_marketing: false
            }
        },
        computed: {
            buttonIcon: function() {
                if (this.loading) {
                    return ['fal', 'spinner-third'];
                }
                else {
                    return ['fal', 'angle-right'];
                }
            }
        },
        methods: {
            submitRegistration: function() {
                let formData = new FormData(document.getElementById('registration_form'));
                formData.append('username', formData.get('email'))

                this.loading = true;

                axios({
                    method: 'post',
                    url: window.location,
                    data: formData,
                    headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onRegister', 'X-Requested-With': 'XMLHttpRequest' }
                }).then(response => {
                    window.location.replace(response.data.redirect_url);
                    this.loading = false;
                }).catch(e => {
                    this.loading = false;
                    if (e.response?.status == 406) {
                        this.errors = e.response.data.X_OCTOBER_ERROR_FIELDS;
                    } else {
                        this.generalError = e.message;
                        throw e;
                    }
                });
            }
        }
    }
</script>