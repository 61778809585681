<template>
    <label for="toggleResultDetails" class="switch-label">
        <div class="switch">
            <input id="toggleResultDetails" type="checkbox" v-model="showDetails" class="switch-input" v-on:change="toggleDetails">
            <span class="switch-paddle"></span>
            <span class="switch-text-label">More details</span>
        </div>
    </label>
</template>

<script>
    // import { EventBus } from './../event-bus.js';
    // export default {
    //     data() {
    //         return {
    //             showDetails: true
    //         }
    //     },
    //     methods: {
    //         toggleDetails() {
    //             EventBus.$emit('toggled-result-details', this.showDetails);
    //         }
    //     }
    // }
</script>