<template>
    <div id="account_menu" class="mb-20">

        <button @click.prevent="state.open=!state.open" class="lg:hidden border bg-gray-50/50 border-gray-100 font-semibold p-10 mb-10">
            {{ state.open ? 'Close menu' : 'Account Menu' }}
        </button>

        <div class="flex flex-col gap-5 mb-30" :class="{ 'hidden lg:flex' : !state.open } ">
            <a v-for="item in items" :href="item.url" class="bg-orange-50 lg:bg-transparent hover:text-orange flex items-center p-15 lg:px-0">
                <font-awesome-icon :icon="['fal', item.icon]" fixed-width class="mr-10 text-orange"></font-awesome-icon>{{ item.label }}
            </a>
        </div>
    </div>
</template>

<script setup>

import { reactive, ref } from 'vue';

const state = reactive({
    open: false
})

const items = ref([
    {
        url: '/account/profile',
        label: 'Edit Profile',
        icon: 'user'
    },
    {
        url: '/account/bookings',
        label: 'Course Bookings',
        icon: 'list'
    },
    {
        url: '/account/attendees',
        label: 'Manage Saved Attendees',
        icon: 'users'
    },
    {
        url: '/account/addresses',
        label: 'Manage Saved Addresses',
        icon: 'map-marker'
    },
    {
        url: '/account/shop-orders',
        label: 'Shop Order History',
        icon: 'shopping-cart'
    },
    {
        url: '/account/password',
        label: 'Change Password',
        icon: 'lock'
    },
    {
        url: '/logout',
        label: 'Logout',
        icon: 'sign-out'
    },
])

</script>