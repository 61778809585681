<template>
    <Carousel v-bind="settings" :breakpoints="breakpoints">
        <Slide v-for="courseDate in props.courseDates" :key="courseDate.id">
            <div class="w-full h-full flex items-stretch">
                <div class="w-full mx-10 flex flex-col items-stretch bg-white rounded overflow-hidden">
                    <div>
                        <img :src="courseDate.image_url" class="object-cover w-full h-300" />
                    </div>
                    <div class="flex flex-1 flex-col items-start text-left p-15 sm:px-30 sm:py-25">
                        <a :href="courseDate.url" class="flex-1 text-sm font-bold mb-5 lg:mb-10 hover:text-orange transition-all duration-300">{{ courseDate.course_name }}</a>
                        <p class="text-sm"><font-awesome-icon :icon="['fal', 'map-marker']" fixed-width class="text-orange mr-10 lg:mr-20"></font-awesome-icon>{{ courseDate.venue_location }}</p>
                        <p class="text-sm mb-10 xl:mb-20"><font-awesome-icon :icon="['fal', 'calendar']" fixed-width class="text-orange mr-10 lg:mr-20"></font-awesome-icon>{{ formatDate(courseDate.start_date, "Do MMMM YYYY") }}</p>
                        <a :href="courseDate.url" class="rounded font-semibold text-orange border-2 border-orange p-10 xl:p-15 text-center self-stretch hover:bg-orange-50 transition-all duration-300">Book Now</a>
                    </div>
                </div>
            </div>
        </Slide>
    </Carousel>
</template>

<script setup>

import { ref } from 'vue'
import moment from 'moment'

const props = defineProps({
    courseDates: {
        type: Array,
        default: []
    }
})

const settings = ref({
    itemsToShow: 1.2,
    wrapAround: true,
    autoplay: 3000
})

const breakpoints = ref({
    550: {
        itemsToShow: 1.8
    },
    768: {
        itemsToShow: 2.2
    },
    1024: {
        itemsToShow: 3.5
    },
    1450: {
        itemsToShow: 4
    },
    1820: {
        itemsToShow: 5
    }
})

function formatDate(date, format) {
    return moment(date).format(format);
}

</script>