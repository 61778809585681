<template>
    <button id="nav-toggle"
        @click.prevent="toggleNav"
        :class="{ 'is-active' : this.active }"
        class="flex items-center content-center justify-center px-10 py-5 md:px-15 md:py-10 cursor-pointer z-20 outline-none rounded-none lg:hidden transition text-xs md:text-sm text-white">
        <font-awesome-icon :icon="['fal', menuIcon]" fixed-width class="mb-0 mr-5 text-gray" />Menu
    </button>
</template>

<script>
    export default {
        data() {
            return {
                active: false
            }
        },

        computed: {
            menuIcon() {
                return this.active ? 'times' : 'bars'
            }
        },

        watch: {
            active() {
                this.emitter.emit('toggled-nav', this.active)
            }
        },

        methods: {
            toggleNav() {
                this.active = !this.active
            }
        }
    }
</script>