<template>
    <Carousel v-bind="settings" :breakpoints="breakpoints">
        <Slide v-for="course in state.courses" :key="course.id">
            <div class="w-full h-full flex items-stretch ">
                <a :href="course.url" class="w-full relative mx-10 lg:mx-30 flex flex-col bg-gray-900 hover:bg-orange-500 text-white rounded-lg lg:h-[300px] xl:h-[420px] group px-40 pt-20 md:pt-30 pb-[74px] md:pb-[114px] xl:pb-[114px] lg:max-w-[338px] transition-all duration-530">
                    <div class="flex-1 flex flex-col justify-center ">
                        <p class="md:text-lg lg:text-xl font-bold text-center">{{ course.title }}</p>
                    </div>
                    <span href="#" class="absolute left-1/2 -translate-x-1/2 bottom-20 md:bottom-30 lg:bottom-0 lg:translate-y-1/2 group-hover:translate-y-0 group-hover:bottom-30 transition-all duration-500 whitespace-nowrap rounded-full py-5 md:py-10 lg:py-15 px-20 md:px-40 lg:px-60 xl:px-80 bg-white flex items-center justify-center font-semibold text-xs lg:text-base text-center text-black lg:text-white group-hover:text-black">
                        {{ cta }} <font-awesome-icon :icon="['fal', 'arrow-right']" class="ml-5 lg:ml-10"></font-awesome-icon>
                    </span>
                </a>
            </div>
        </Slide>
    </Carousel>
</template>

<script setup>

import { computed, onMounted, reactive, ref } from 'vue';

const cta = computed(() => {
    return props.courses.length > 0 ? 'Find a date' : 'Find out more'
});

onMounted(() => {
    if (props.courses.length <= 0) {
        if (props.groupId > 0) {
            getCourses()
        }
    } else {
        state.courses = props.courses
    }
})

const state = reactive({
    courses: []
});

const props = defineProps({
    courses: {
        type: Array,
        default: []
    },
    groupId: {
        type: Number,
        default: 0
    }
})

const settings = ref({
    itemsToShow: 1.2,
    wrapAround: true,
    autoplay: 3000
})

const breakpoints = ref({
    550: {
        itemsToShow: 1.8
        ,
    },
    768: {
        itemsToShow: 2.2,
    },
    1024: {
        itemsToShow: 3.5,
    },
    1450: {
        itemsToShow: 4,
    },
    1820: {
        itemsToShow: 5,
    },
})

function getCourses() {
    axios.post(window.location.href, { groupId: props.groupId }, {
        'headers' : {
            'X-OCTOBER-REQUEST-HANDLER': 'onGetGroupables',
            'X-Requested-With' :'XMLHttpRequest'
        }
    }).then(response => {
        state.courses = response.data
    }).catch(error => {
    }).then(() => {
    });
}

</script>