<template>
    <div class="p-20 hover:bg-blue-50 transition-colors duration-500"
        :class="{ 'border-2 border-black shadow-md' : editingAttendee, 'border-b border-gray-200' : !editingAttendee }">
        <template v-if="editingAttendee">

            <div v-if="Object.keys(errors).length > 0"
                @click.prevent="errors={}" class="bg-red-50 text-red-500 p-10 rounded mb-10 cursor-pointer">
                <p class="font-bold">Failed to update attendee</p>
                <ValidationError v-if="errors.name" class="text-red-500 text-sm" @click.prevent="errors.name=false">{{ errors.name[0] }}</ValidationError>
                <ValidationError v-if="errors.last_name && !errors.name" class="text-red-500 text-sm" @click.prevent="errors.last_name=false">{{ errors.last_name[0] }}</ValidationError>
                <ValidationError v-if="errors.email" class="text-red-500 text-sm" @click.prevent="errors.email=false">{{ errors.email[0] }}</ValidationError>
                <ValidationError v-if="errors.unique_email" class="text-red-500 text-sm" @click.prevent="errors.unique_email=false">{{ errors.unique_email[0] }}</ValidationError>
                <ValidationError v-if="errors.phone" class="text-red-500 text-sm" @click.prevent="errors.phone=false">{{ errors.phone[0] }}</ValidationError>
            </div>

            <form class="flex flex-col sm:flex-row flex-wrap" @submit.prevent="saveAttendee">
                <div class="flex-1 mb-15 md:mb-0 sm:pr-5 md:pr-10">
                    <TextInput name="name" placeholder="Full name" v-model="attendee.full_name" class="w-full" />
                </div>
                <div class="flex-1 mb-15 md:mb-0 sm:pl-5 md:px-10">
                    <TextInput name="email" placeholder="Email address" v-model="attendee.email" class="w-full" />
                </div>
                <div class="flex-1 mb-15 md:mb-0 sm:pl-5 md:px-10">
                    <TextInput name="phone" placeholder="Phone number" v-model="attendee.phone" class="w-full" />
                </div>
                <div class="w-full md:w-auto md:flex-shrink md:pl-10">
                    <SubmitButton class="w-full">
                        Save
                    </SubmitButton>
                </div>
            </form>
        </template>
        <template v-else>
            <div class="flex items-center">
                <div class="flex-1 flex flex-col">
                    {{ attendee.full_name }} <span class="text-xs text-gray">({{ attendee.email }})</span>
                </div>
                <div class="flex-shrink">
                    <div v-if="removingAttendee">
                        <button class="px-10 py-5 text-sm text-white font-semibold rounded bg-gray-600 hover:bg-gray-500" @click.prevent="removingAttendee=false">Cancel</button>
                        <button class="px-10 py-5 text-sm text-white font-semibold rounded bg-red-400 hover:bg-red-300 ml-5" @click.prevent="removeAttendee">Confirm</button>
                    </div>
                    <template v-else>
                        <button class="py-5 text-sm hover:underline hover:text-blue-500" @click.prevent="editingAttendee=true">Edit</button>
                        <button class="py-5 text-sm hover:underline hover:text-red-500 ml-10" @click.prevent="removingAttendee=true">Remove</button>
                    </template>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                attendee: {},
                removingAttendee: false,
                editingAttendee: false,
                errors: {},
                saving: false
            }
        },
        props: [
            'initAttendee',
            'searchExcludes',
            'orderId'
        ],
        mounted() {
            this.attendee = this.initAttendee
        },
        methods: {
            saveAttendee() {
                this.saving = true
                this.errors = {}

                axios.post(window.location.href, { attendee: this.attendee, orderId: this.orderId }, {
                    'headers': {
                        'X-OCTOBER-REQUEST-HANDLER': 'onUpdateAttendee',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(() => {
                    this.editingAttendee=false
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.error = error.response.data
                        throw error;
                    }
                }).then(() => {
                    this.saving = false
                });
            },

            removeAttendee() {
                this.$emit('removing-attendee', this.attendee)
            }
        }
    }
</script>