<template>
    <form id="login-form">
        <div v-show="passwordResetSuccess" @click="passwordResetSuccess=false" class="rounded bg-green-100 text-green-800 py-10 px-20 cursor-pointer mb-20 font-semibold">
            <p>{{ passwordResetSuccess }}</p>
            <p>Enter your email address and new password below to login.</p>
        </div>
        <div v-show="!showReset">
            <p v-if="loginFailed" class="text-sm text-red-500 my-5 cursor-pointer mb-10" @click="loginFailed=false">{{ loginFailed }}</p>
            <div class="flex flex-col mb-20">
                <FormLabel>Email address</FormLabel>
                <TextInput type="email" name="login" />
                <ValidationError v-if="errors.login" @click="errors.login=false">{{ errors.login[0] }}</ValidationError>
            </div>
            <div class="flex flex-col mb-20">
                <FormLabel>Password</FormLabel>
                <PasswordInput name="password" />
                <ValidationError v-if="errors.password" @click="errors.password=false">{{ errors.password[0] }}</ValidationError>
            </div>
        </div>
        <p v-if="!showReset" class="text-sm leading-none">
            Forgot your password? <a @click.prevent="showReset=!showReset" class="text-sm font-semibold text-blue-500 hover:underline hover:text-blue-600 cursor-pointer">Click here to reset your password?</a>
        </p>
        <a v-else @click.prevent="showReset=false" class="text-blue font-semibold underline cursor-pointer">Cancel password reset</a>
        <reset-password v-show="showReset" @password-reset-successfully="passwordReset" :reset-code="passwordResetCode"></reset-password>
    </form>
</template>

<script>
    export default {

        data() {
            return {
                errors: {},
                loginFailed: false,
                showReset: false,
                passwordResetSuccess: false
            }
        },
        watch: {
            showReset(newValue) {
                this.emitter.emit('toggled-password-reset', newValue);
            }
        },
        props: [
            'checkoutOrder', 'passwordResetCode'
        ],
        mounted() {
            if (this.passwordResetCode) {
                this.showReset = true
            }
            this.emitter.on('login', () => {
                this.login()
            })
        },
        methods: {
            login() {
                this.loginFailed = false

                let formData = new FormData(document.getElementById('login-form'))
                formData.append('redirectUrl', window.location.href)
                formData.append('orderId', this.checkoutOrder.id)

                axios.post(window.location.href, formData, {
                    'headers': {
                        'X-OCTOBER-REQUEST-HANDLER': 'onCheckoutLogin',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    window.location = response.data.redirectUrl
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.loginFailed = error.response.data
                        throw error;
                    }
                }).then(() => {
                    this.emitter.emit('finished-register-login')
                })
            },

            passwordReset(successMessage) {
                this.showReset = false
                this.passwordResetSuccess = successMessage
            }
        }
    }
</script>
