<template>
    <div id="manage_addresses">

        <div class="flex flex-col md:flex-row md:items-center md:justify-between mb-10 sm:mb-15 md:mb-20 lg:mb-30 xl:mb-40 2xl:mb-50">
            <p class="text-xl font-bold mb-10 sm:mb-15 md:mb-0">Manage Saved Addresses</p>
            <div>
                <button v-if="!state.addressFormOpen" v-on:click="toggleNewAddressForm" id="new_address_button" type="button" class="p-10 bg-black text-white font-semibold rounded">
                    <font-awesome-icon :icon="['fal', 'plus']" class="mr-5"></font-awesome-icon>Add a new address
                </button>
                <button v-else v-on:click="toggleNewAddressForm" id="new_address_cancel_button" type="button" class="p-10 bg-black text-white font-semibold rounded">
                    <font-awesome-icon :icon="['fal', 'times']" class="mr-5"></font-awesome-icon>Cancel
                </button>
            </div>
        </div>

        <form v-if="state.addressFormOpen" id="address_form" @submit.prevent="saveAddress" method="POST" class="bg-gray-50 border border-gray-200 rounded-lg p-30 mb-50">
            <div class="">

                <h4 class="mb-30 text-2xl font-semibold">{{ state.addressFormMode == "edit" ? "Edit" : "Add a new" }} address</h4>

                <div class="grid gap-10 md:gap-20 lg:gap-30 grid-cols-1 md:grid-cols-2 mb-10 md:mb-20 lg:mb-30">

                    <div class="flex flex-col" v-bind:class="{ error: state.formErrors.first_name }">
                        <FormLabel for="first_name">First Name <span class="req">*</span></FormLabel>
                        <TextInput name="first_name" id="first_name" v-model="state.addressModel.first_name" />
                        <ValidationError v-if="state.formErrors.first_name" class="text-xs text-red-500">{{ state.formErrors.first_name[0] }}</ValidationError>
                    </div>

                    <div class="flex flex-col" v-bind:class="{ error: state.formErrors.last_name }">
                        <FormLabel for="last_name">Last Name <span class="req">*</span></FormLabel>
                        <TextInput name="last_name" id="last_name" v-model="state.addressModel.last_name" />
                        <ValidationError v-if="state.formErrors.last_name" class="text-xs text-red-500">{{ state.formErrors.last_name[0] }}</ValidationError>
                    </div>


                    <div class="flex flex-col" v-bind:class="{ error: state.formErrors.address_1 }">
                        <FormLabel for="address_1">Address line 1 <span class="req">*</span></FormLabel>
                        <TextInput name="address_1" id="address_1" v-model="state.addressModel.address_1" />
                        <ValidationError v-if="state.formErrors.address_1" class="text-xs text-red-500">{{ state.formErrors.address_1[0] }}</ValidationError>
                    </div>

                    <div class="flex flex-col" v-bind:class="{ error: state.formErrors.address_2 }">
                        <FormLabel for="address_2">Address line 2</FormLabel>
                        <TextInput name="address_2" id="address_2" v-model="state.addressModel.address_2" />
                        <ValidationError v-if="state.formErrors.address_2" class="text-xs text-red-500">{{ state.formErrors.address_2[0] }}</ValidationError>
                    </div>

                    <div class="flex flex-col" v-bind:class="{ error: state.formErrors.city_town }">
                        <FormLabel for="city_town">City / Town <span class="req">*</span></FormLabel>
                        <TextInput name="city_town" id="city_town" v-model="state.addressModel.city_town" />
                        <ValidationError v-if="state.formErrors.city_town" class="text-xs text-red-500">{{ state.formErrors.city_town[0] }}</ValidationError>
                    </div>

                    <div class="flex flex-col" v-bind:class="{ error: state.formErrors.county }">
                        <FormLabel for="county">County <span class="req">*</span></FormLabel>
                        <SelectInput v-model="state.addressModel.county" :options="state.counties" name="county" id="county" ></SelectInput>
                        <ValidationError v-if="state.formErrors.county" class="text-xs text-red-500">{{ state.formErrors.county[0] }}</ValidationError>
                    </div>

                    <div class="flex flex-col" v-bind:class="{ error: state.formErrors.postcode }">
                        <FormLabel for="postcode">Postcode <span class="req">*</span></FormLabel>
                        <TextInput name="postcode" id="postcode" v-model="state.addressModel.postcode" />
                        <ValidationError v-if="state.formErrors.postcode" class="text-xs text-red-500">{{ state.formErrors.postcode[0] }}</ValidationError>
                    </div>

                    <div class="flex flex-col">
                        <FormLabel for="is_default_label">Default address</FormLabel>
                        <label for="is_default">
                            <Checkbox id="is_default" name="is_default" v-model="state.addressModel.is_default" theme="light">
                                Make this my default address
                            </Checkbox>
                        </label>
                    </div>
                </div>

                <button class="p-10 bg-orange rounded text-white font-semibold">Save <font-awesome-icon :icon="saveAddressIcon" v-bind:spin="state.loading" /></button>
            </div>
        </form>

        <div id="address_list">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-30 ">
                <template v-for="address in state.addresses">
                    <div class="border border-gray-200 rounded-lg">
                        <div class="flex items-center justify-between bg-gray-50 p-15 text-xs">
                            <div class="">
                                <span v-if="address.is_default" class="font-bold">Default Address</span>

                                <button v-else @click.prevent="makeDefault(address)" class="py-[7px] px-10 rounded text-xs bg-black text-white">
                                    <font-awesome-icon :icon="['fal', 'star']" class="mr-5"/>Make default
                                </button>
                            </div>
                            <div class="">
                                <button v-show="!address.removeConfirm" v-on:click="address.removeConfirm = true" class="py-[7px] px-10 rounded text-xs bg-red-500 text-white font-semibold">
                                    <font-awesome-icon :icon="['fal', 'trash']" class="mr-5"/>Remove
                                </button>
                                <template v-if="state.loading">
                                    <button v-show="address.removeConfirm" v-on:click="removeAddress(address)" class="py-[7px] px-10 rounded text-xs bg-red-500 text-white font-semibold">
                                        <font-awesome-icon :icon="['fal', 'spinner-third']" spin class="mr-5"/>Removing
                                    </button>
                                </template>
                                <template v-else>
                                    <button v-show="address.removeConfirm" v-on:click="removeAddress(address)" class="py-[7px] px-10 rounded text-xs bg-red-500 text-white font-semibold">
                                        <font-awesome-icon :icon="['fal', 'trash']" class="mr-5"/>Confirm
                                    </button>
                                    <button v-show="address.removeConfirm" v-on:click="address.removeConfirm = false" class="py-[7px] px-10 rounded text-xs bg-black text-white font-semibold ml-10">
                                        <font-awesome-icon :icon="['fal', 'times']" class="mr-5"/>Cancel
                                    </button>
                                </template>

                                <button v-on:click="editAddress(address)" type="button" class="py-[7px] px-10 rounded text-xs bg-blue text-white ml-10">
                                    <font-awesome-icon :icon="['fal', 'pencil']" fixed-width class="mr-5"/>Edit
                                </button>
                            </div>
                        </div>
                        <div class="border-t border-gray-200 p-15">
                            <p>{{ address.first_name }} {{ address.last_name }}</p>
                            <p>{{ address.address_1 }}</p>
                            <p>{{ address.address_2 }}</p>
                            <p>{{ address.county }}</p>
                            <p>{{ address.postcode }}</p>
                        </div>
                    </div>
                </template>
            </div>
            <p v-show="!state.addresses || state.addresses.length == 0" class="text-center" style="margin:1rem;">No addresses found. Click the button above to add a new address.</p>
        </div>
    </div>
</template>
<script setup>

import axios from 'axios';
import { reactive, onMounted, computed } from 'vue'

const state = reactive({
    generalError: false,
    formErrors : false,
    loading: false,
    addressModel: false,
    addressFormMode: 'new',
    addressFormOpen: false,
    addresses: false,
    counties:false
})

onMounted(() => {
    getCounties()
    getAddresses()
    clearForm()
})

const saveAddressIcon = computed(() => {
    if (state.loading) {
        return ['fal', 'spinner-third'];
    }
    else {
        return ['fal', 'plus'];
    }
})

function toggleNewAddressForm() {
    state.addressFormMode = 'new';
    state.addressFormOpen = !state.addressFormOpen;
    clearForm();
}

function getCounties () {
    axios.post(window.location.href, {}, { headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onGetCounties', 'X-Requested-With': 'XMLHttpRequest' }})
    .then(response => {
        state.counties = response.data;
    })
    .catch(e => {
        state.counties = false;
    });
}

function getAddresses() {
    state.loading = true;
    axios.post(window.location.href, {}, { headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onGetAddresses', 'X-Requested-With': 'XMLHttpRequest' }})
    .then(response => {
        state.loading = false;
        state.addresses = response.data;
    })
    .catch(e => {
        state.loading = false;
        state.addresses = false;
    });
}

function saveAddress() {
    state.loading = true;
    state.formErrors = false;

    axios({
        method: 'post',
        url: window.location.href,
        data: state.addressModel,
        headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onSaveAddress', 'X-Requested-With': 'XMLHttpRequest' }
    })
    .then (response => {
        state.addressFormOpen = false;
        state.addressModel = false;
        getAddresses();
    })
    .catch(e => {
        state.loading = false;
        if (e.response?.status == 406) {
            state.formErrors = e.response.data.X_OCTOBER_ERROR_FIELDS;
        }
        else {
            state.generalError = e.message;
            throw e;
        }
    });
}

function clearForm() {
    state.addressModel = {
        address_1: "",
        address_2: "",
        city_town: "",
        county: "",
        postcode: "",
        is_default: false
    }
}

function removeAddress(address) {
    if (!state.loading) {
        state.loading = true;
        axios({
            method: 'post',
            url: window.location.href,
            data: { id : address.id },
            headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onRemoveAddress', 'X-Requested-With': 'XMLHttpRequest' }
        })
        .then (response => {
            state.loading = false;
            getAddresses();
        })
        .catch(e => {
            state.loading = false;
        });
    }
}

function makeDefault (address) {
    address.is_default = 2;
    axios({
        method: 'post',
        url: window.location.href,
        data: { id : address.id },
        headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onMakeAddressDefault', 'X-Requested-With': 'XMLHttpRequest' }
    })
    .then (response => {
        address.is_default = 1;
        getAddresses();
    })
    .catch(e => {
        address.is_default = 0;
    });
}

function editAddress(address) {
    state.addressFormOpen = true;
    state.addressFormMode = 'edit';
    state.addressModel = address;
}
</script>