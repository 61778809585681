<template>
    <div class="w-full rounded border border-gray-50 mb-10 last:mb-0">
        <a href="#" @click.prevent="open = !open" class="cursor-pointer flex justify-between p-15 bg-gray-50/50 hover:bg-gray-50 transition-all duraiton-300">
            <p class="font-semibold text-lg"><slot name="heading"></slot></p>
            <span><font-awesome-icon :icon="['fal', buttonIcon]"></font-awesome-icon></span>
        </a>
        <Transition name="accordion">
            <div v-if="open" class="transition-all duration-[1s] overflow-hidden">
                <div class="editor-styles p-30">
                    <slot></slot>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script setup>

import { computed, ref } from 'vue';

const open = ref(false)

const buttonIcon = computed(() => {
    return open.value == true ? 'minus' : 'plus'
})
</script>

<style>
.accordion-leave-to,
.accordion-enter-from {
    opacity: 0;
    max-height: 0;
}

.accordion-enter-to,
.accordion-leave-from {
    max-height: 1000px;
}
</style>