<template>
    <VueDatePicker
        :uid="props.id"
        v-model="value"
        input-class-name="border p-10 rounded h-[48px]"
        locale="en-GB"
        auto-apply
        :format="formattedDate"
        :enable-time-picker="false"
        class="relative">
    </VueDatePicker>
</template>

<script setup>

import { computed } from 'vue'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const emit = defineEmits(['update:model-value'])

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:model-value', value)
    }
})

const formattedDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1; // getMonth is zero indexed
    const year = date.getFullYear();

    return  `${day}/${month}/${year}`
}

const props = defineProps(['modelValue', 'id'])

</script>