<template>
    <Transition name="modal">
        <div v-show="open" class="overflow-auto z-20 fixed top-0 left-0 w-full h-full items-start justify-center flex bg-black/70 backdrop-blur-sm transtion-all duration-300 p-10 md:p-30 lg:pt-40 pb-0">
            <button @click="$emit('close')" class="absolute flex items-center justify-center w-30 h-30 rounded-full top-10 right-10 bg-white hover:bg-gray-100 text-lg transition-all duration-300 z-10">
                <font-awesome-icon :icon="['fal', 'times']"></font-awesome-icon>
            </button>
            <div class="relative" :class="props.containerClasses">
                <slot></slot>
            </div>
        </div>
    </Transition>
</template>

<script setup>
    const props = defineProps({
        open: Boolean,
        containerClasses: {
            default: "container h-5/6 p-30 p-30 bg-white rounded-md shadow-lg transition-all duration-300"
        }
    })
</script>

<style>

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>