<template>
    <section id="course-dates" class="pt-40 py-30">

        <div class="lg:grid lg:gap-30 lg:grid-cols-[300px_1fr] xl:grid-cols-[474px_1fr] lg:grid-rows-[1fr_auto]">
            <div class="col-start-1 row-start-1">
                <div class="sticky top-20">
                    <p class="text-lg font-bold mb-20">Availability and pricing</p>
                    <div class="flex gap-10 items-stretch mb-20 lg:hidden">
                        <button @click.prevent="state.showFilters =! state.showFilters" class="flex-1 border p-10 rounded flex justify-between items-center">
                            {{ state.showFilters ? "Close Filters" : "Show Filters" }}
                            <font-awesome-icon :icon="['fal', filtersButtonIcon]" fixed-width></font-awesome-icon>
                        </button>

                        <select v-model="state.order_by" class="flex-1 border p-10 rounded" @change="getCourseDates(false)" id="course-dates-order-input">
                            <option v-if="state.filters.type!='online'" value="distance">Distance</option>
                            <option value="start_date">Date</option>
                            <option value="price_per_attendee">Price</option>
                        </select>
                    </div>

                    <CourseDateFilters
                        :show-online="props.showOnline"
                        :show-in-person="props.showInPerson"
                        :show-filters="state.showFilters"
                        :init-filters="initFilters"
                        :course-type="state.filters.type"
                        @filters-updated="updateFilters"
                        :validation-errors="state.filtersValidationErrors">
                    </CourseDateFilters>
                </div>
            </div>
            <div class="col-start-2 row-start-1 row-span-2">
                <template v-if="state.courseDates.length > 0">
                    <CourseDate v-for="courseDate in state.courseDates" :key="courseDate.id" :course-date="courseDate"></CourseDate>

                    <button v-if="state.lastPage > state.page"
                        class="w-full rounded mt-10 p-20 bg-black text-white"
                        @click.prevent="loadMore" >Load more course dates</button>
                </template>

                <div v-else-if="state.searching" class="text-blue font-semibold bg-blue-50 p-10 md:p-20 lg:p-50 rounded mb-30 lg:mb-0 flex items-center justify-center">
                    <font-awesome-icon :icon="['fal', 'spinner-third']" spin fixed-width class="mr-10"></font-awesome-icon> Searching for available course dates.
                </div>

                <div v-else class="bg-blue-50 p-10 md:p-20 lg:p-50 rounded mb-30 lg:mb-0">
                    <p class="text-center text-blue font-2xl font-semibold mb-20">No course dates found</p>
                    <p class="text-center text-blue">Adjust the search criteria to find dates, or contact our sales team to discuss your requirements.</p>
                </div>
            </div>
            <div class="col-start-1 row-start-2 col-span-1">
                <slot>Enquire about Incompany training</slot>
            </div>
        </div>

    </section>
</template>

<script setup>

import { watch, computed, onMounted, reactive } from 'vue';
import CourseDate from './CourseDate.vue'
import CourseDateFilters from './CourseDateFilters.vue'

const state = reactive({
    searching: false,
    type: 'in-person',
    showFilters: false,
    courseDates: [],
    filters: {},
    per_page: 10,
    page: 1,
    order_by: 'start_date',
    filtersValidationErrors: [],
    lastPage: null
});

watch(() => state.filters.type, (type) => {
    if (type == "online" && state.order_by == "distance") {
        state.order_by = "start_date"
    }
    getCourseDates()
}, { deep: true } )

const props = defineProps({
    initFilters: {
        type: Object
    },
    showOnline: {
        type: Boolean,
        default: false
    },
    showInPerson: {
        type: Boolean,
        default: false
    }
})

const ajaxHandler = computed(() => {
    return state.filters.type == "online" ? 'onGetOnlineCourseDates' : 'onGetCourseInstances'
})

onMounted(() => {
    state.filters = props.initFilters

    if (!props.showInPerson) {
        state.filters.type = "online"
    }
})

const filtersButtonIcon = computed(() => {
    return state.showFilters ? 'times' : 'angle-down'
})

function updateFilters(filters) {
    state.filters = filters
    state.page = 1
    getCourseDates()
}

function getCourseDates(appendDates = false)
{
    state.searching = true
    state.filtersValidationErrors = []

    if (appendDates == false) {
        state.courseDates = []
        state.lastPage = null
    }

    axios({
        method: 'post',
        url: window.location.href,
        data: {
            course_id: state.filters.course_id,
            filters: state.filters,
            page: state.page,
            per_page: state.per_page,
            order_by: state.order_by
        },
        headers: {
            'X-OCTOBER-REQUEST-HANDLER' : ajaxHandler.value,
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
    .then(response => {
        if (appendDates) {
            state.courseDates.push(...response.data.data);
        } else {
            state.courseDates = response.data.data;
        }
        state.lastPage = response.data.last_page
    })
    .catch(error => {
        if (error.response?.status) {
            if (error.response.status === 406) {
                state.filtersValidationErrors = error.response.data.X_OCTOBER_ERROR_FIELDS
            }
        } else {
            throw error;
        }
    })
    .then(() => {
        state.searching = false
    })
}

function loadMore() {
    state.page++
    getCourseDates(true)
}

</script>