import Glide from '@glidejs/glide'

import jquery from 'jquery'
window.$ = window.jQuery = jquery;

/**
 * Axios
 */
import axios from 'axios'
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

import { createApp } from 'vue'

/**
 * Sentry
 */
import * as Sentry from "@sentry/browser";

function handleSentryBeforeSend(event, hint) {
    if (axios.isCancel(hint?.originalException)) {
        return null;
    }
    return event;
}

Sentry.init({
    dsn: "https://38e2f128357c75fcb16418bd161d89df@o4508358341033984.ingest.de.sentry.io/4508363987877968",
    environment: process.env.NODE_ENV || 'development',
    integrations: [
        Sentry.replayIntegration(),
        Sentry.feedbackIntegration({
            // Additional SDK configuration goes in here, for example:
            colorScheme: "light",
            autoInject: window.showFeedback ?? false,
        }),
        Sentry.thirdPartyErrorFilterIntegration({
            filterKeys: ['jYNhqPzGn'],
            behaviour: "drop-error-if-contains-third-party-frames",
        })
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    ignoreErrors: [
        'Non-Error promise rejection captured'
    ],
    beforeSend: handleSentryBeforeSend
});

/**
 * Font Awesome
 */
import { icons } from './icons.js'
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/**
 * Emitter for global events
 */
import Emitter from 'tiny-emitter/instance'

/**
 * Navigation Components
*/
import NavToggle from './components/nav/NavToggle.vue'
import NavBar from './components/nav/NavBar.vue'
import NavList from './components/nav/NavList.vue'
import NavListItem from './components/nav/NavListItem.vue'
import LogoutButton from './components/nav/LogoutButton.vue'

/**
 * Form Elements
*/
import Callout from './components/form/Callout.vue'
import Checkbox from './components/form/Checkbox.vue'
import CheckboxGroup from './components/form/CheckboxGroup.vue'
import DatePicker from './components/form/DatePicker.vue'
import FormLabel from './components/form/FormLabel.vue'
import GoogleAutoComplete from './components/form/GoogleAutoComplete.vue'
import PasswordInput from './components/form/PasswordInput.vue'
import RadioInput from './components/form/RadioInput.vue'
import SelectInput from './components/form/SelectInput.vue'
import SubmitButton from './components/form/SubmitButton.vue'
import ValidationError from './components/form/ValidationError.vue'
import TextInput from './components/form/TextInput.vue'
import TextareaInput from './components/form/TextareaInput.vue'

/**
 * Account Components
 */
import AccountHeading from './components/account/AccountHeading.vue'
import AccountMenu from './components/account/AccountMenu.vue'
import AccountAddresses from './components/account/AccountAddresses.vue'
import AccountAttendees from './components/account/AccountAttendees.vue'
import AccountBookings from './components/account/AccountBookings.vue'
import AccountDashboard from './components/account/AccountDashboard.vue'
import AccountProfile from './components/account/AccountProfile.vue'
import AccountPassword from './components/account/AccountPassword.vue'
import LoginForm from './components/account/LoginForm.vue'
import RegistrationForm from './components/account/RegistrationForm.vue'
import ResetPassword from './components/account/ResetPassword.vue'

/**
 * ESS Components
*/
import Modal from './components/Modal.vue'
import Tile from './components/course/Tile.vue'
import FindCourse from './components/FindCourse.vue'
import Course from './components/course/Course.vue'
import CourseDates from './components/course/CourseDates.vue'
import CourseCalendar from './components/coursecalendar/CourseCalendar.vue'
import CourseCarousel from './components/CourseCarousel.vue'
import CourseDownload from './components/course/CourseDownload.vue'
import CourseDatesCarousel from './components/CourseDatesCarousel.vue'
import ContactForm from './components/ContactForm.vue'
import BookingEnquiry from './components/BookingEnquiry.vue'
import ContinueBookingBanner from './components/ContinueBookingBanner.vue'
import AccordionItem from './components/AccordionItem.vue'

/**
 * All things search
 */
import CourseSearchModal from './components/search/CourseSearchModal.vue'
import SearchResults from './components/search/SearchResults.vue'
import SearchCourseTypesPicker from './components/search/SearchCourseTypesPicker.vue'
import SearchFilters from './components/search/SearchFilters.vue'
import SearchResultItem from './components/search/SearchResultItem.vue'
import SearchResultsDetailsToggle from './components/search/SearchResultsDetailsToggle.vue'

/**
 * Other third party components
 */
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'

library.add(icons)

let app = createApp();

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('logout-button', LogoutButton);
app.component('nav-toggle', NavToggle);
app.component('nav-bar', NavBar);
app.component('nav-list', NavList);
app.component('nav-list-item', NavListItem);
app.component('Callout', Callout);
app.component('Checkbox', Checkbox);
app.component('CheckboxGroup', CheckboxGroup);
app.component('GoogleAutoComplete', GoogleAutoComplete);
app.component('RadioInput', RadioInput);
app.component('SelectInput', SelectInput);
app.component('TextInput', TextInput);
app.component('TextareaInput', TextareaInput);
app.component('PasswordInput', PasswordInput);
app.component('DatePicker', DatePicker);
app.component('FormLabel', FormLabel);
app.component('ValidationError', ValidationError);
app.component('SubmitButton', SubmitButton);

app.component('AccountHeading', AccountHeading);
app.component('account-menu', AccountMenu);
app.component('account-addresses', AccountAddresses);
app.component('account-attendees', AccountAttendees);
app.component('account-bookings', AccountBookings);
app.component('account-dashboard', AccountDashboard);
app.component('account-profile', AccountProfile);
app.component('account-password', AccountPassword);
app.component('login-form', LoginForm);
app.component('registration-form', RegistrationForm);
app.component('reset-password', ResetPassword);

app.component('tile', Tile);
app.component('Modal', Modal);
app.component('FindCourse', FindCourse);
app.component('Course', Course);
app.component('CourseDates', CourseDates);
app.component('CourseCalendar', CourseCalendar);
app.component('CourseCarousel', CourseCarousel);
app.component('CourseDatesCarousel', CourseDatesCarousel);
app.component('Carousel', Carousel);
app.component('Slide', Slide);
app.component('contact-form', ContactForm);
app.component('booking-enquiry', BookingEnquiry);
app.component('continue-booking-banner', ContinueBookingBanner);
app.component('course-download', CourseDownload);
app.component('accordion-item', AccordionItem);

app.component('CourseSearchModal', CourseSearchModal);
app.component('search-results', SearchResults);
app.component('SearchCourseTypesPicker', SearchCourseTypesPicker);
app.component('SearchFilters', SearchFilters);
app.component('SearchResultItem', SearchResultItem);
app.component('SearchResultsDetailsToggle', SearchResultsDetailsToggle);

/**
 * Register Emitter for globale events
 */
app.config.globalProperties.emitter = Emitter
app.config.globalProperties.$filters = {
    snakeToTitle(str) {
        return str.split('_').map(function (item) {
            return item.charAt(0).toUpperCase() + item.substring(1);
        }).join(' ');
    }
}

if (document.getElementById('app')) {
    app.mount('#app')
}

import CheckoutAddress from './components/checkout/CheckoutAddress.vue'
import CheckoutAttendees from './components/checkout/CheckoutAttendees.vue'
import CheckoutConfirm from './components/checkout/CheckoutConfirm.vue'
import CheckoutResetPassword from './components/checkout/CheckoutResetPassword.vue'
import CheckoutUser from './components/checkout/CheckoutUser.vue'
import CheckoutUserDetailsProfile from './components/checkout/CheckoutUserDetailsProfile.vue'
import CheckoutUserLogin from './components/checkout/CheckoutUserLogin.vue'
import CheckoutUserRegister from './components/checkout/CheckoutUserRegister.vue'
import NewAttendee from './components/checkout/NewAttendee.vue'
import OrderAttendee from './components/checkout/OrderAttendee.vue'

let checkout = createApp();

checkout.component('font-awesome-icon', FontAwesomeIcon)

checkout.component('checkout-address', CheckoutAddress)
checkout.component('checkout-attendees', CheckoutAttendees)
checkout.component('checkout-confirm', CheckoutConfirm)
checkout.component('reset-password', CheckoutResetPassword)
checkout.component('checkout-user', CheckoutUser)
checkout.component('checkout-user-details-profile', CheckoutUserDetailsProfile)
checkout.component('checkout-user-login', CheckoutUserLogin)
checkout.component('checkout-user-register', CheckoutUserRegister)
checkout.component('new-attendee', NewAttendee)
checkout.component('order-attendee', OrderAttendee)

checkout.component('TextInput', TextInput);
checkout.component('TextareaInput', TextareaInput);
checkout.component('PasswordInput', PasswordInput);
checkout.component('FormLabel', FormLabel);
checkout.component('ValidationError', ValidationError);
checkout.component('SubmitButton', SubmitButton);
checkout.component('Checkbox', Checkbox)
checkout.component('RadioInput', RadioInput)

checkout.config.globalProperties.emitter = Emitter

if (document.getElementById('checkoutApp')) {
    checkout.mount('#checkoutApp')
}

let scrollToLinks = document.querySelectorAll('[data-scroll-to]');

scrollToLinks.forEach(link => {
    link.addEventListener('click', function handleClick(event) {
        event.preventDefault();
        let targetElement = event.target.getAttribute('data-scroll-to').replace(/^#/, "");
        let el = document.getElementById(targetElement);
        if (el) {
            el.scrollIntoView({ behavior: 'smooth' })
        }
    })
})

document.querySelectorAll('[data-course-glider]').forEach(function (element) {
    let numSlides = element.querySelectorAll('.glide__slide').length;
    let perView = Math.min(5, numSlides);

    let breakpoints = {
        1820: {
            perView: Math.min(5, numSlides),
        },
        1450: {
            perView: Math.min(4, numSlides),
        },
        1024: {
            perView: Math.min(3, numSlides),
        },
        865: {
            perView: Math.min(2, numSlides),
        },
        530: {
            perView: 1,
        }
    };

    new Glide(element, {
        type: 'slider',
        bound: true,
        startAt: 0,
        gap: 0,
        perView: perView,
        autoplay: 3000,
        breakpoints: breakpoints
    }).mount()
});
