<template>
    <div id="booking_history">
        <AccountHeading>Course Bookings</AccountHeading>

        <template v-if="state.bookings.upcoming_bookings">
            <AccountHeading>Upcoming Bookings</AccountHeading>
            <div class="mb-20 md:mb-30 lg:mb-50">
                <AccountBooking v-for="booking in state.bookings.upcoming_bookings" :key="booking.id" :booking="booking" />
            </div>
        </template>

        <template v-if="state.bookings.past_bookings">
            <AccountHeading>Past Bookings</AccountHeading>
            <AccountBooking v-for="booking in state.bookings.past_bookings" :key="booking.id" :booking="booking" />
        </template>

    </div>
</template>

<script setup>

import { onMounted, reactive } from 'vue';
import AccountBooking from './AccountBooking.vue'

const state = reactive({
    generalError: false,
    errors: false,
    loading: false,
    bookings: [],
})

onMounted(() => {
    getBookings()
})

function getBookings() {
    state.loading = true;

    axios({
        method: 'post',
        url: window.location,
        headers: { 'X-OCTOBER-REQUEST-HANDLER': 'onGetBookings' }
    }).then(response => {
        state.loading = false;
        state.bookings = response.data;
    }).catch(e => {
        state.loading = false;
    });
}
</script>