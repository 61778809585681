<template>
    <div>
        <div class="flex flex-col md:flex-row gap-10 md:gap-30 mb-10 md:mb-20">
            <div class="w-full md:w-2/5 lg:w-1/4 font-semibold hidden md:block">Search Options</div>
            <div class="flex-1" v-if="state.results && state.results.length > 0 && !state.searching" >
                <div class="font-semibold lg:hidden">We found {{ state.count }} results.</div>
                <div class="font-semibold hidden lg:block">
                    Your search <span v-if="state.term"> for "{{ state.term }}"</span> returned {{ state.count }} results
                </div>
            </div>
            <p v-if="state.results && state.results.length > 0 && !state.searching" class="flex-shrink font-semibold">Page {{ state.currentPage }} of {{ state.lastPage }}</p>
        </div>

        <div class="flex flex-col md:flex-row gap-30">
            <div class="w-full md:w-2/5 lg:w-1/4 sticky top-10 bg-white z-10">
                <form @submit.prevent="onSearch" class="sticky top-10 bg-white">
                    <SearchFilters :initTerm="props.initTerm" :initCourseType="props.initCourseType" v-on:filters-changed="onFilterChange" />
                </form>
            </div>
            <div class="flex-1">
                <div v-if="state.searching" class="h-full flex flex-col justify-center items-center">
                    <p class="text-xl font-bold mb-20"><font-awesome-icon :icon="['fal', 'spinner-third']" spin /></p>
                    <p class="font-semibold mb-40">Fetching available Courses, NVQs and Apprenticeships...</p>
                </div>
                <template v-else-if="state.results && state.results.length > 0">
                    <a v-if="state.category" :href="state.category.slug" class="text-lg font-semibold block bg-orange hover:bg-orange-400 text-black rounded w-full p-20 mb-15 transition-all duration-300">
                        {{ state.category.title }}
                    </a>
                    <div class="grid gap-30 grid-cols-1 lg:grid-cols-2 mb-30">
                        <SearchResultItem v-for="result in state.results" :key="result.id" :result="result" />
                    </div>
                    <div class="grid grid-cols-2">
                        <button v-show="previousPage > 0" @click.prevent="showPreviousPage()" class="justify-self-start p-10 text-white rounded bg-black"><font-awesome-icon :icon="['fal', 'angle-left']" class="mr-5"></font-awesome-icon>Previous</button>
                        <button v-show="nextPage <= state.lastPage" @click.prevent="showNextPage()" class="justify-self-end p-10 text-white rounded bg-black">Next<font-awesome-icon :icon="['fal', 'angle-right']" class="mr-5"></font-awesome-icon></button>
                    </div>
                </template>
                <template v-else-if="state.searchFailed">
                    <div class="flex flex-col justify-center items-center">
                        <p class="text-xl font-bold mb-20">Unable to search courses</p>
                        <p class="font-semibold mb-40">Something went wrong while searching for courses. Please try again.</p>
                        <p class="font-semibold mb-40">If the problem persists please contact us</p>
                        <div class="flex flex-col gap-20" >
                            <a class="text-center font-semibold rounded hover:bg-orange bg-black text-white p-20" href="tel:01158970529">Call us on 0115 8970 529</a>
                            <a class="text-center hover:text-orange " href="/contact">Go to contact page</a>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="flex flex-col justify-center items-center">
                        <p class="text-xl font-bold mb-20">Your search has not found any results.</p>
                        <p class="font-semibold mb-40">Please contact our training team for more information and they will be happy to help.</p>
                        <div class="flex flex-col gap-20" >
                            <a class="text-center font-semibold rounded hover:bg-orange bg-black text-white p-20" href="tel:01158970529">Call us on 0115 8970 529</a>
                            <a class="text-center hover:text-orange " href="/contact">Go to contact page</a>
                        </div>
                    </div>
                </template>
            </div>
        </div>

    </div>
</template>

<script setup>

import { reactive, computed } from 'vue'
import axios from 'axios';

const props = defineProps(['initCount', 'initResults', 'initTerm', 'initCourseType', 'initCurrentPage', 'initCategory', 'initLastPage'])

const state = reactive({
    searchId: "",
    term: props.initTerm,
    courseType: props.initCourseType,
    count: props.initCount,
    results: props.initResults,
    currentPage: props.initCurrentPage,
    lastPage: props.initLastPage,
    category: props.initCategory,
    showDetails: true,
    searching: false,
    searchFailed: false
})

const nextPage = computed(() => {
    return parseInt(state.currentPage)+1
})

const previousPage = computed(() => {
    return parseInt(state.currentPage)-1
})

function onSearch(event, showPage = 1) {
    window.history.pushState({}, '', '/search?search_id='+state.searchId+'term='+state.term+'&courseType='+state.courseType+'&page='+showPage);
    state.searching = true;
    axios({
        method: 'post',
        url: window.location,
        data: {
            term: state.term,
            courseType: state.courseType,
            page: state.showPage
        },
        headers: {
            'X-OCTOBER-REQUEST-HANDLER': 'onSearch',
            'X-Requested-With': 'XMLHttpRequest'
        }
    })
    .then(response => {
        state.count   = response.data.count;
        state.results = response.data.results;
        state.category = response.data.category;
        state.currentPage = response.data.pagination.currentPage;
        state.lastPage = response.data.pagination.lastPage;
    })
    .catch(error => {
        state.searchFailed = true;
        throw error;
    })
    .then((data) => {
        state.searching = false;
    });
}

function onFilterChange(filters) {
    state.term = filters.term;
    state.courseType = filters.courseType;
    state.searchId = filters.searchId;
    onSearch();
}

function showNextPage(e) {
    onSearch(e, nextPage.value);
}

function showPreviousPage(e) {
    onSearch(e, previousPage.value);
}
</script>