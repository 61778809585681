<template>
    <ul v-if="navItems" :class="[listClass, listClasses]">
        <nav-list-item
            v-for="(item, index) in navItems"
            :key="index"
            :item="item"
            :top-level="topLevel"
            :user-logged-in="userLoggedIn">
        </nav-list-item>
    </ul>
</template>

<script>
    export default {
        props: {
            navItems: {
                type: [Object, Array],
                default: false
            },
            listClass: {
                type: [Object, String],
                default: ''
            },
            topLevel: {
                type: Boolean,
                default: false
            },
            userLoggedIn: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            listClasses() {
                return {
                    'lg:flex lg:justify-start lg:w-full' : this.topLevel,
                    'border-l-4 border-orange-500 lg:border-gray-800 lg:-mt-[1px] lg:hidden lg:bg-white lg:border lg:rounded-b mx-auto' : !this.topLevel
                }
            },
        }
    }
</script>