<template>
    <textarea type="text" v-model="value" class="w-full p-10 text-gray-700 bg-white border border-black rounded font-semibold outline-none"></textarea>
</template>

<script setup>

import { computed } from 'vue';

const emit = defineEmits(['update:model-value'])

const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:model-value', value)
    }
})

const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    }
})

</script>