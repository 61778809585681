<template>
    <div class="flex flex-col items-center mb-30">
        <div class="mx-auto w-full max-w-[700px] border border-gray-300 rounded mb-30">
            <div class="bg-gray-100 p-20 border-b border-gray-300 rounded-tr rounded-tl flex items-center">
                <div class="flex-1 text-lg font-semibold">Attendees</div>
                <div class="flex-shrink font-bold">Price <span class="text-xs mr-5">(exc. VAT)</span> <span class="text-pink">{{ order.formatted_subtotal }}</span></div>
            </div>
            <div v-if="order" class="bg-white rounded-br rounded-bl">
                <order-attendee
                    v-for="attendee in order.attendees"
                    :key="attendee.id"
                    :init-attendee="attendee"
                    :order-id="order.id"
                    @removing-attendee="removeAttendee" />
                <new-attendee
                    v-if="showAddAttendeeForm"
                    :order-id="order.id"
                    :search-excludes="order.attendees"
                    :is-closable="order.attendees.length > 0"
                    :add-existing-error="addExistingError"
                    @cancelled-new-attendee="addNewAttendee=false, addExistingError=false"
                    @created-attendee="addAttendeeToOrder"
                    @added-existing-attendee="addAttendeeToOrder"/>
                <div v-else class="p-20 flex items-center justify-center">
                    <button class="bg-black text-white font-semibold p-10 rounded" @click.prevent="addNewAttendee=true">
                        <font-awesome-icon :icon="['fal', 'plus']" class="mr-5"></font-awesome-icon> Add another attendee</button>
                </div>
            </div>
        </div>
        <SubmitButton m
            @click.prevent="onProcessAttendees"
            :class="{ 'opacity-40 cursor-not-allowed' : disableContinueButton }"
            :disabled="disableContinueButton">
            Continue
        </SubmitButton>
    </div>
</template>

<script>

    export default {

        data() {
            return {
                loading: false,
                order: { attendees: [] },
                addNewAttendee: false,
                errors: false,
                error: false,
                addExistingError: false
            }
        },

        props: [
            'checkoutOrder'
        ],

        computed: {
            showAddAttendeeForm() {
                return this.addNewAttendee || (this.order.attendees.length == 0)
            },

            disableContinueButton() {
                return this.order.attendees.length < 1
            }
        },

        mounted() {
            this.order = this.checkoutOrder
        },

        methods: {
            addAttendeeToOrder(attendee) {
                axios.post(window.location.href, {
                    'attendee_id': attendee.id,
                    'order_id': this.order.id
                }, {
                    'headers': {
                        'X-OCTOBER-REQUEST-HANDLER' : 'onAddAttendee',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    this.order = response.data.order
                    this.addNewAttendee = false
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.addExistingError = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        throw error;
                    }
                }).then(() => {
                })
            },

            removeAttendee(attendee) {
                axios.post(window.location.href, {
                    attendee_id: attendee.id,
                    order_id: this.order.id
                }, {
                    'headers': {
                        'X-OCTOBER-REQUEST-HANDLER' : 'onRemoveAttendee',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    this.order = response.data.order
                }).catch(error => {
                }).then(() => {
                })
            },

            onProcessAttendees() {
                this.loading = true
                this.errors = {}
                this.error = false
                axios.post(window.location.href, {
                    order_id: this.order.id
                }, {
                    'headers': {
                        'X-OCTOBER-REQUEST-HANDLER' : 'onProcessAttendees',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then(response => {
                    window.location = response.data.redirect_url
                }).catch(error => {
                    if (error.response?.status == 406) {
                        this.errors = error.response.data.X_OCTOBER_ERROR_FIELDS
                    } else {
                        this.error = error.response.data
                        throw error;
                    }
                }).then(() => {
                    this.loading = false
                })
            }
        }
    }
</script>