<template>
    <div class="pt-30">
        <div class="flex gap-10 mb-10">
            <div id="month" class="relative flex-1">
                <p class="text-xs font-bold">Month</p>
                <button :class="monthButtonClasses" @click="toggleMonthDropdown"><span>{{ state.activeMonth }}</span><font-awesome-icon :icon="monthDropdownIcon" fixed-width class="ml-5"></font-awesome-icon></button>
                <ul :class="monthListClasses">
                    <template v-for="month in months">
                        <li class="lg:flex-1 flex border-b border-gray-300 last:border-b-none lg:border-none lg:text-center">
                            <button :class="{ 'bg-red lg:bg-orange-500 lg:hover:bg-orange-500': (state.activeMonth == month) }" class="w-full lg:rounded p-10 bg-white lg:text-sm lg:bg-black lg:hover:bg-orange lg:text-white disabled:bg-gray-100 disabled:hover:bg-gray-100 disabled:text-gray-500" @click.prevent="changeMonth(month)" :disabled="!checkMonth(month)">{{ month }}</button>
                        </li>
                    </template>
                </ul>
            </div>

            <div id="year" class="relative flex-1 lg:flex-none lg:flex-shrink">
                <p class="text-xs font-bold">Year</p>
                <button :class="yearButtonClasses" @click="toggleYearDropdown"><span>{{ state.activeYear }}</span><font-awesome-icon :icon="yearDropdownIcon" fixed-width class="ml-5"></font-awesome-icon></button>
                <ul :class="yearListClasses">
                    <li v-for="year in years" :class="{ active: (state.activeYear == year) }" class="flex bg-white border-b border-gray-300 last:border-b-none lg:border-none">
                        <button class="rounded w-full p-10 bg-white hover:bg-orange hover:text-white" @click.prevent="changeYear(year)">{{ year }}</button>
                    </li>
                </ul>
            </div>
        </div>

        <div class="flex flex-col md:flex-row gap-10 ">
            <div id="day" class="relative flex-1 md:flex-none md:flex-shrink whitespace-nowrap md:min-w-[120px]">
                <p class="text-xs font-bold pr-20">Show dates from</p>
                <button :class="dayButtonClasses" @click="toggleDayDropdown"><span>{{ dayLabel }}</span><font-awesome-icon :icon="dayDropdownIcon" fixed-width class="ml-5"></font-awesome-icon></button>
                <ul :class="dayListClasses">
                    <li v-for="day in days" :class="{ active: (state.activeDay == day) }" class="flex">
                        <button :class="{ 'bg-orange text-white': (state.activeDay == day), 'bg-white': (state.activeDay != day) }" class="rounded w-full p-10 hover:bg-orange hover:text-white" @click.prevent="changeDay(day)">{{ day }}</button>
                    </li>
                </ul>
            </div>
            <div class="flex-1 flex flex-col">
                <FormLabel>Search for a course</FormLabel>
                <TextInput v-model="course" class="w-full" placeholder="e.g. Asbestos Awareness UKATA" />
            </div>
        </div>

        <div id="course-calendar-results">
            <template v-if="state.courseInstances.length > 0">
                <p class="lg:text-right my-10 text-blue-500 font-bold text-xs">Prices shown are exclusive of VAT.</p>
                <CalendarCourseDate v-for="courseInstance in state.courseInstances" :key="courseInstance.id" :course-instance="courseInstance" />
            </template>
            <template v-else>
                <div v-if="!state.loading" class="callout text-center">
                    No courses found matching this selection. Please make another selection or <a href="/contact-us">contact our training team</a> for more information
                </div>
            </template>

            <div v-if="state.loading" class="text-lg text-center bg-orange-50 border border-orange-100 p-30 mt-10 text-orange font-medium" >
                <font-awesome-icon :icon="['fal', 'spinner-third']" spin class="mr-10"/>
                <span>Fetching available Courses...</span>
            </div>

            <div class="text-center">
                <button v-show="state.showLoadMore && !state.loading" class="loadMoreButton" ref="loadMoreIntersect" @click.prevent="getCourseInstances(false)">Load more</button>
            </div>

        </div>
    </div>
</template>

<style>
    .loadMoreButton {
        background: #EEEEEE;
        font-size: 10px;
        padding: 20px;
        margin: 30px auto;
    }
</style>

<script setup>
import axios from 'axios';
import CalendarCourseDate from './CalendarCourseDate.vue'

import moment from 'moment'
import debounce from 'lodash.debounce'
import { watch, onMounted, ref, reactive, computed } from 'vue';

const props = defineProps([
    'firstAvailabilityDate',
    'initDay',
    'initMonth',
    'initYear',
    'initCourse',
])

const dayLabel = computed(() => {
    return moment(`${state.activeDay}-${state.activeMonth}-${state.activeYear}`, 'DD-MMM-YYYY').format('Do')
})

const days = computed(() => {
    let month = moment(`${state.activeMonth}-${state.activeYear}`, 'MMM-YYYY')
    return month.daysInMonth()
})

const months = ref({
    1 : "Jan",
    2 : "Feb",
    3 : "Mar",
    4 : "Apr",
    5 : "May",
    6 : "Jun",
    7 : "Jul",
    8 : "Aug",
    9 : "Sep",
    10 : "Oct",
    11 : "Nov",
    12 : "Dec",
})

const years = ref({
    1: moment().format('YYYY'),
    2: moment().add(1, 'y').format('YYYY'),
})

const course = ref("")

const state = reactive({
    activeDay: props.initDay,
    activeMonth: props.initMonth,
    activeYear: props.initYear,
    monthDropdownOpen: false,
    yearDropdownOpen: false,
    loading: true,
    courseInstances: [],
    offset: 0,
    showLoadMore: true,
    windowWidth: 0,
})

const monthButtonClasses = computed(() => {
    return {
        'text-sm lg:hidden w-full bg-black text-white px-15 py-10 flex items-center justify-between' : true,
        'rounded' : !state.monthDropdownOpen,
        'rounded-t' : state.monthDropdownOpen
    }
})

const monthListClasses = computed(() => {
    return {
        'absolute z-10 lg:relative w-full flex lg:gap-5 flex-col lg:flex-row items-stretch border border-black lg:border-none bg-white': true,
        'flex': state.monthDropdownOpen,
        'hidden lg:flex': !state.monthDropdownOpen,
    }
})

const dayButtonClasses = computed(() => {
    return {
        'text-sm min-w-[90px] w-full bg-white px-15 py-[11.5px] flex items-center justify-between border border-black' : true,
        'rounded' : !state.dayDropdownOpen,
        'rounded-t' : state.dayDropdownOpen
    }
})

const dayListClasses = computed(() => {
    return {
        'bg-gray-100 lg:bg-white absolute w-[250px] grid grid-cols-5 gap-5 lg:gap-0 border border-black rounded-b p-5': true,
        'block': state.dayDropdownOpen,
        'hidden': !state.dayDropdownOpen,
    }
})

const yearButtonClasses = computed(() => {
    return {
        'text-sm w-full bg-black text-white px-15 py-10 flex items-center justify-between' : true,
        'rounded' : !state.yearDropdownOpen,
        'rounded-t' : state.yearDropdownOpen
    }
})

const yearListClasses = computed(() => {
    return {
        'absolute z-10 w-full flex flex-col items-stretch border border-black p-5 bg-white': true,
        'block': state.yearDropdownOpen,
        'hidden': !state.yearDropdownOpen,
    }
})

const monthDropdownIcon = computed(() => {
    return state.monthDropdownOpen ? ['fal', 'times'] : ['fal', 'angle-down'];
})

const yearDropdownIcon = computed(() => {
    return state.yearDropdownOpen ? ['fal', 'times'] : ['fal', 'angle-down'];
})

const dayDropdownIcon = computed(() => {
    return state.dayDropdownOpen ? ['fal', 'times'] : ['fal', 'angle-down'];
})

/**
 * Ajax request to retrieve course instances based
 * on the users current date selection.
 */
function getCourseInstances(reset = false) {
    state.loading = true

    if (reset) {
        state.courseInstances = []
        state.offset = 0
        state.showLoadMore = true
    }

    axios({
        method: 'post',
        url: window.location,
        headers: {
            'X-OCTOBER-REQUEST-HANDLER' : 'onGetCourseInstances',
            'X-Requested-With' : 'XMLHttpRequest'
        },
        data: {
            course : course.value,
            day : state.activeDay,
            month : moment(state.activeMonth+1, "MMM").format('M'),
            year : state.activeYear,
            offset: state.offset
        }
    }).then(response => {
        if (response.data.courses.length == 0) {
            state.showLoadMore = false;
        } else {
            state.showLoadMore = true;
            state.courseInstances = state.courseInstances.concat(response.data.courses);
            state.offset = response.data.offset
        }

    }).catch(errors => {
    }).then(() => {
        state.loading = false;
    });
}

/**
 * Get the month with earliest available course dates
 * and set the activeMonth before updating the courses
 */
function getCourseInstancesOnYearChange() {
    state.offset = 0;
    state.courseInstances = [];

    axios({
        method: 'post',
        url: window.location,
        headers: {
            'X-OCTOBER-REQUEST-HANDLER' : 'onGetEarliestAvailableMonthForYear',
            'X-Requested-With' : 'XMLHttpRequest'
        },
        data: {
            year: state.activeYear
        }
    }).then(response => {
        state.activeMonth = response.data.activeMonth
        getCourseInstances()
    }).catch(errors => {

    }).then(() => {

    });
}

/**
 * Checks whether the first available course has a start date
 * that is before the last day of the given month of the
 * activeYear so we can decide whether to disable the
 * the month option in the date picker
 */
function checkMonth(month) {
    const lastDayOfMonth = moment(month+'-'+state.activeYear, 'MMM-YYYY').add(1, 'M').subtract(1, 'seconds');
    const firstAvailability = moment(props.firstAvailabilityDate, 'MMM-YYYY');
    return firstAvailability.isBefore(lastDayOfMonth);
}

/**
 * Update the browser history so users can
 * user the forward and back buttons in
 * their browser
 */
function updateHistory() {
    const month = moment(state.activeMonth+1, "MMM").format('MMM');
    window.history.pushState({}, '', window.location.pathname+'?day='+state.activeDay+'&month='+month+'&year='+state.activeYear+'&course='+course.value);
}

function changeDay(day) {
    state.activeDay = day
    state.dayDropdownOpen = !state.dayDropdownOpen;
    updateHistory()
    getCourseInstances(true);
}

function changeMonth(month)
{
    state.activeDay = 1
    state.activeMonth = month;
    state.monthDropdownOpen = !state.monthDropdownOpen;
    updateHistory();
    getCourseInstances(true)
}

function changeYear(year)
{
    state.activeDay = 1
    state.activeYear = year;
    state.yearDropdownOpen = !state.yearDropdownOpen;
    updateHistory();
    getCourseInstancesOnYearChange()
}

const loadMoreIntersect = ref(null)

onMounted(() => {

    course.value = props.initCourse

    getCourseInstances()

    const observerOptions = {
        root: null,
        rootMargin: "-150px 0px 0px 0px",
        thresholds: [1]
    }

    const observer = new IntersectionObserver(handleIntersections, observerOptions)
    const observerTarget = loadMoreIntersect._value
    observer.observe(observerTarget)

    state.windowWidth = window.innerWidth

    window.addEventListener("resize", debounceResize(handleWindowResize))
})

function handleIntersections(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting && (state.courseInstances.length > 0)) {
            getCourseInstances()
        }
    })
}

function handleWindowResize() {
    let w = window.innerWidth
    if ((w < 1024 && state.windowWidth >= 1024) || (w >= 1024 && state.windowWidth < 1024)) {
        state.yearDropdownOpen = false
        state.monthDropdownOpen = false
    }

    state.windowWidth = w
}

function debounceResize(func){
    var timer
    return function(event){
        if(timer) clearTimeout(timer)
        timer = setTimeout(func,100,event)
    }
}

function toggleDayDropdown() {
    state.dayDropdownOpen = !state.dayDropdownOpen
    state.monthDropdownOpen = false
    state.yearDropdownOpen = false
}

function toggleMonthDropdown() {
    state.dayDropdownOpen = false
    state.monthDropdownOpen = !state.monthDropdownOpen
    state.yearDropdownOpen = false
}

function toggleYearDropdown() {
    state.dayDropdownOpen = false
    state.monthDropdownOpen = false
    state.yearDropdownOpen = !state.yearDropdownOpen
}

const debouncedWatch = debounce(() => {
    updateHistory()
    getCourseInstances(true)
}, 500)

watch(course, debouncedWatch)

</script>